import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import {
  TCarouselContainer,
  TCarouselSlidesState,
  TCollapseContainer,
  TContainer,
  TContainerPosition,
  TProductContainer,
  TRepeatingList,
} from '../../../api/types/paywallTemplate.types';
import {
  applyStyles,
  flexDirection,
  pickAndApplyBackgroundColor,
} from '../css';
import TemplateComponent from '../TemplateComponent';
import HoverTag from './HoverTag';

type ContainerProps = {
  component:
    | TContainer
    | TProductContainer
    | TCarouselContainer
    | TCollapseContainer;
  inFocusedState?: boolean;
  groupId: string | null;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
};

export const ContainerWrapper = styled.div<{
  component: Omit<
    | TContainer
    | TProductContainer
    | TCarouselContainer
    | TCollapseContainer
    | TRepeatingList,
    'name'
  >;
  inFocusedState?: boolean;
  minSDKVersion: TSemverObj;
}>`
  display: flex;

  ${({ component, inFocusedState, minSDKVersion }) => {
    const isVertical = component.direction === 'vertical';
    const sizeKey = isVertical ? 'width' : 'height';
    const spaceKey = isVertical ? 'margin-top' : 'margin-left';
    const background = !!component.fillImage
      ? css`
          background-image: url(${component.fillImage});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        `
      : pickAndApplyBackgroundColor(component, inFocusedState);
    return css`
      ${background};
      ${parsePosition(component.position)}
      ${flexDirection(component)}
      ${applyStyles(component, inFocusedState)}

      > * {
        ${applyChildSizing(minSDKVersion, sizeKey)}
        &:not(:first-child) {
          ${spaceKey}: ${component.spacing || 0}px;
        }
      }
    `;
  }}
`;

export default function Container({
  component,
  inFocusedState,
  groupId,
  slides,
  minSDKVersion,
}: ContainerProps) {
  return (
    <HoverTag
      title={
        component?.namiComponentType ? component.title || component.id : ''
      }
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <ContainerWrapper
        component={component}
        inFocusedState={inFocusedState}
        minSDKVersion={minSDKVersion}
      >
        {(component.components || []).map((child, i) => (
          <TemplateComponent
            key={i}
            component={child}
            inFocusedState={inFocusedState}
            groupId={groupId}
            slides={slides}
            minSDKVersion={minSDKVersion}
          />
        ))}
      </ContainerWrapper>
    </HoverTag>
  );
}

export function parsePosition(
  position?: TContainerPosition
): string | FlattenSimpleInterpolation {
  if (!position) return '';
  const [alignment, spot] = position.split('-');
  return css`
    align-self: ${alignment};
    ${spot}: 0;
  `;
}

export function applyChildSizing(minSDKVersion: TSemverObj, sizeKey: string) {
  //Apply child sizing only to paywalls below 3.2
  if (minSDKVersion.minor < 2) {
    return css`
      ${sizeKey}: 100%;
    `;
  }
  return css``;
}
