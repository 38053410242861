import React, { useMemo } from 'react';

import { AppstoreAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Image, Select, Space, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { AppType } from '../../api/types/app.types';
import { useAppSelector } from '../../hooks/redux.hooks';
import { defaultImage } from '../../services/helpers';

type AppSwitchPickerProps = {
  onAppClick: (appId: string) => void;
};

export default function AppSwitchPicker({ onAppClick }: AppSwitchPickerProps) {
  const history = useHistory();
  const { currentApp, apps } = useAppSelector(({ root }) => ({
    currentApp: root.currentApp,
    apps: root.apps as AppType[], // TODO: Remove when "root" is properly typed
  }));

  const appMenuItems = useMemo(() => {
    return apps.map((app) => {
      return {
        label: (
          <Space direction="horizontal" size={8} wrap={false} align="start">
            <Image
              height={18}
              width={18}
              style={{
                cursor: 'pointer',
                borderRadius: 4,
                transform: 'translateY(-2px)',
              }}
              preview={false}
              src={app.logo || defaultImage(app.name)}
            />
            {app.name}
          </Space>
        ),
        key: app.id,
        value: app.id,
      };
    });
  }, [apps]);

  return (
    <Tooltip
      title="Select app workspace"
      mouseEnterDelay={0.3}
      placement="right"
    >
      <Select
        value={currentApp?.id}
        options={appMenuItems}
        style={{ width: 200 }}
        onChange={(value) => onAppClick(value)}
        dropdownMatchSelectWidth={false}
        className="intercom-desktopPickApp"
        placeholder={
          <Space direction="horizontal">
            <PlusOutlined />
            Add App Workspace
          </Space>
        }
        dropdownRender={(menu) => (
          <>
            {menu}
            <Button
              type="text"
              icon={<AppstoreAddOutlined />}
              style={{ marginTop: 12 }}
              block={true}
              onClick={() => history.push('/settings/?tab=apps')}
            >
              Manage Apps
            </Button>
          </>
        )}
      />
    </Tooltip>
  );
}
