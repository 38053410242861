import moment from 'moment';
import { TEventLogItem } from 'src/api/types/developer.types';
import { platformLogo } from 'src/services/helpers';

import { capitalize } from './formatting';

export const DEV_MODE = (function (): boolean {
  const value = process.env.REACT_APP_DEV_MODE;
  if (!value) return false;
  try {
    return JSON.parse(value.toLowerCase());
  } catch (e) {
    console.error(e);
    return false;
  }
})();

export type EventLineItem = {
  key: string;
  name: string;
  value: string;
  copyValue?: string;
  copyable: boolean;
  link?: string;
  image?: string;
};

export function convertEventToLineItems(event: TEventLogItem): EventLineItem[] {
  let result: EventLineItem[] = [];
  result.push(
    {
      key: event.id,
      name: 'Id',
      value: event.id,
      copyable: true,
    },
    {
      key: event.created_date,
      name: 'Date',
      value: moment(event.created_date).format('YYYY-MM-DD h:mm:ss A'),
      copyValue: event.created_date,
      copyable: true,
    }
  );

  if (event.event.data) {
    if (event.event.data.app_env) {
      result.push({
        key: event.event.data.app_env,
        name: 'App Environment',
        value: event.event.data.app_env,
        copyable: false,
      });
    }

    if (event.event.data.call_to_action) {
      result.push({
        key: event.event.data.call_to_action,
        name: 'Paywall',
        value:
          event.event.data.call_to_action_name ||
          event.event.data.call_to_action,
        copyable: false,
        link: `/paywalls/${event.event.data.call_to_action}`,
      });
    }
    if (event.event.data.amount) {
      result.push({
        key: event.event.data.amount,
        name: 'Amount',
        value: `${event.event.data.amount} ${event.event.data.currency || ''}`,
        copyable: false,
      });
    }
    if (event.event.data.sku) {
      result.push({
        key: event.event.data.sku,
        name: 'Product',
        value:
          event.event.data.sku_name ||
          event.event.data.sku_ref_id ||
          event.event.data.sku,
        copyable: false,
        link: `/products/${event.event.data.sku}`,
      });
    }
    if (event.event.data.original_transaction_id) {
      result.push({
        key: event.event.data.original_transaction_id,
        name: 'Original Transaction ID',
        value: event.event.data.original_transaction_id,
        copyable: true,
      });
    }
    if (event.event.data.transaction_id) {
      result.push({
        key: event.event.data.transaction_id,
        name: 'Transaction ID',
        value: event.event.data.transaction_id,
        copyable: true,
      });
    }
  }

  if (event.event) {
    if (event.event.user_id) {
      result.push({
        key: event.event.user_id,
        name: 'User ID',
        value: event.event.user_id,
        copyable: true,
        link: `/customers/${event.event.user_id}/`,
      });
    }
    if (event.event.event_platform) {
      result.push({
        key: event.event.event_platform,
        name: 'Platform',
        value: capitalize(event.event.event_platform),
        copyable: false,
      });
    }
    if (
      event.event.active_entitlements &&
      event.event.active_entitlements.length > 0
    ) {
      if (event.event.active_entitlements[0].sku_ref_id) {
        result.push({
          key: event.event.active_entitlements[0].sku_ref_id,
          name: 'Product',
          value:
            event.event.active_entitlements[0].sku_name ||
            event.event.active_entitlements[0].sku_ref_id,
          copyable: false,
          //link: `/products/${event.event.data.sku}`, //TODO
        });
      }
    }
  }

  if (event.device) {
    result.push(
      {
        key: event.device.id,
        name: 'Device ID',
        value: event.device.id,
        copyable: true,
        //link: `/customers?device_id=${event.device.id}`, //TODO
      },
      {
        key: event.device.platform.name,
        name: 'Platform',
        value: event.device.platform.name,
        copyable: false,
        link: `/integrations/${event.device.platform.platform_type}/${event.device.platform.id}`,
        image: platformLogo(event.device.platform.platform_type),
      }
    );
  }
  return result;
}

export function getListDescriptionForEvent(event: TEventLogItem): string {
  if (['device.session.start', 'device.session.end'].includes(event.event_type))
    return '';
  if (event.event_type === 'device.impression')
    return event.event.data?.call_to_action_name || '';
  if (event.event_type === 'device.transaction')
    return event.event.data?.sku_name || event.event.data?.sku_ref_id || '';
  if (event.event_type.startsWith('user.subscription.')) {
    if (event.event.active_entitlements && event.event.active_entitlements[0]) {
      return (
        event.event.active_entitlements[0].sku_name ||
        event.event.active_entitlements[0].sku_ref_id
      );
    }
  }
  return '';
}
