import React, { useMemo } from 'react';

import {
  ApiOutlined,
  CreditCardOutlined,
  LockFilled,
  TeamOutlined,
} from '@ant-design/icons';
import {
  AppsOutlined,
  EnhancedEncryptionOutlined,
  IntegrationInstructionsOutlined,
  Logout,
  PaletteOutlined,
  PersonOutline,
  Security,
  TuneOutlined,
  VpnKeyOutlined,
  WorkspacesOutlined,
} from '@mui/icons-material';
import { Avatar, Drawer, Menu, MenuProps, Space, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useAppContext } from 'src/hooks';
import { defaultImage } from 'src/services/helpers';
import {
  namiBlack,
  namiBrightBlue,
  namiNavy,
  namiPureWhite,
} from 'src/variables';
import styled from 'styled-components';

import { SettingsActions, TAB_ENTITLEMENTS_MAP } from './SettingsPage';

const ItemIconMap: Record<SettingsActions, JSX.Element> = {
  account: <PersonOutline />,
  security: <EnhancedEncryptionOutlined />,
  org: <TuneOutlined />,
  apps: <AppsOutlined />,
  team: <TeamOutlined />,
  billing: <CreditCardOutlined />,
  policies: <Security />,
  storeCredentials: <ApiOutlined />,
  'org-integrations': <IntegrationInstructionsOutlined />,
  'api-access': <VpnKeyOutlined />,
  brand: <PaletteOutlined />,
  'all-orgs': <WorkspacesOutlined />,
  logout: <Logout />,
};

const ItemLabelMap: Record<SettingsActions, string> = {
  account: 'Profile',
  security: 'Security',
  org: 'Org Details',
  apps: 'Apps',
  team: 'Team',
  billing: 'Billing',
  policies: 'Security Policies',
  storeCredentials: 'Store Credentials',
  'org-integrations': 'CDP Integrations',
  'api-access': 'API Access',
  brand: 'Brand & Assets',
  'all-orgs': 'Manage Orgs',
  logout: 'Logout',
};

const DrawerDiv = styled.div`
  z-index: 998;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 256px;
  background-color: ${namiNavy};
  padding-top: 60px;
  padding-bottom: 32px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const StyledMenu = styled(Menu)`
  background-color: transparent !important;
  li.ant-menu-item.ant-menu-item-selected {
    background-color: ${namiBlack} !important;
  }

  ul.ant-menu.ant-menu-inline.ant-menu-sub {
    background-color: transparent !important;
  }
`;

const SuperMenuItem = styled(Typography.Text)`
  color: ${namiPureWhite};
  font-weight: 500;
  font-size: 16px;
`;

type SettingsDrawerProps = {
  selectedMenuItem: string | null;
  setSelectedMenuItem: (key: SettingsActions) => void;
  size: 'mdUp' | 'mdDown';
  mobileDrawerOpen: boolean;
};

export default function SettingsDrawer({
  selectedMenuItem,
  setSelectedMenuItem,
  size,
  mobileDrawerOpen,
}: SettingsDrawerProps) {
  const context = useAppContext();
  const user = context?.user;
  const image =
    user?.avatar ||
    defaultImage(
      user?.first_name && user.last_name
        ? `${user.first_name}+${user.last_name}`
        : user?.first_name || ''
    );

  const org = context.selectedOrg;

  const accountMenuItems: MenuProps['items'] = [
    {
      key: 'account-menu',
      label: (
        <Space direction="horizontal" style={{ width: '100%' }}>
          <Avatar src={image} />
          <SuperMenuItem>
            {user?.first_name} {user?.last_name}
          </SuperMenuItem>
        </Space>
      ),
      disabled: false,
      children: [buildMenuItem('account'), buildMenuItem('security')],
    },
  ];

  const menuItems = useMemo(() => {
    if (org) {
      let children: ItemType[] = [
        buildMenuItem('org'),
        buildMenuItem('apps'),
        buildMenuItem('team'),
        buildMenuItem('billing'),
        buildMenuItem('policies'),
        buildMenuItem('storeCredentials'),
      ];

      if (context.userHasEntitlement('org.cdp_integration.list')) {
        children.push(buildMenuItem('org-integrations'));
      }

      children = [
        ...children,
        buildMenuItem('api-access'),
        buildMenuItem('brand'),
      ];
      return [
        ...accountMenuItems,
        {
          key: 'org-menu',
          label: (
            <Space direction="horizontal" style={{ width: '100%' }}>
              <Avatar src={defaultImage(org.name)} />
              <SuperMenuItem>{org?.name}</SuperMenuItem>
            </Space>
          ),
          disabled: false,
          children: children,
        },
      ];
    }
    return accountMenuItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org]);

  const bottomMenuItems: MenuProps['items'] = [
    buildMenuItem('all-orgs'),
    buildMenuItem('logout'),
  ];

  if (size === 'mdDown') {
    return (
      <Drawer
        placement="left"
        width="256px"
        closable={true}
        zIndex={998}
        drawerStyle={{
          backgroundColor: namiNavy,
        }}
        bodyStyle={{
          padding: '18px 0px',
        }}
        open={mobileDrawerOpen}
      >
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <StyledMenu
            theme="dark"
            items={menuItems}
            mode="inline"
            inlineCollapsed={false}
            onClick={(info) => setSelectedMenuItem(info.key as SettingsActions)}
            selectedKeys={selectedMenuItem ? [selectedMenuItem] : undefined}
            defaultOpenKeys={['account-menu']}
            inlineIndent={16}
          />
          <Space direction="vertical" size={4} style={{ width: '100%' }}>
            <StyledMenu
              theme="dark"
              items={bottomMenuItems}
              mode="inline"
              // inlineCollapsed={false}
              style={{ backgroundColor: 'transparent' }}
              onClick={(info) =>
                setSelectedMenuItem(info.key as SettingsActions)
              }
              selectedKeys={selectedMenuItem ? [selectedMenuItem] : undefined}
              defaultOpenKeys={['account', 'org']}
            />
          </Space>
        </Space>
      </Drawer>
    );
  } else {
    return (
      <DrawerDiv>
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          <Space direction="vertical" size={6} style={{ width: '100%' }}>
            <StyledMenu
              theme="dark"
              items={menuItems}
              mode="inline"
              inlineCollapsed={false}
              onClick={(info) =>
                setSelectedMenuItem(info.key as SettingsActions)
              }
              selectedKeys={selectedMenuItem ? [selectedMenuItem] : undefined}
              defaultOpenKeys={['account-menu', 'org-menu']}
              inlineIndent={18}
            />
          </Space>
          <StyledMenu
            theme="dark"
            items={bottomMenuItems}
            mode="inline"
            inlineCollapsed={false}
            style={{ backgroundColor: 'transparent' }}
            onClick={(info) => setSelectedMenuItem(info.key as SettingsActions)}
            selectedKeys={selectedMenuItem ? [selectedMenuItem] : undefined}
          />
        </Space>
      </DrawerDiv>
    );
  }

  function buildMenuItem(key: SettingsActions): ItemType {
    const entitlements = TAB_ENTITLEMENTS_MAP[key];

    let enabled = true;
    let unlocked = true;
    if (entitlements.length > 0) {
      enabled = entitlements.some(context.userHasEntitlement);
      unlocked = entitlements.some(context.planHasEntitlement);
    }

    return {
      key: key,
      label: ItemLabelMap[key],
      icon: getIcon(key, unlocked),
      disabled: unlocked && !enabled,
    };
  }

  function getIcon(key: SettingsActions, unlocked: boolean): JSX.Element {
    if (!unlocked) {
      return <LockFilled style={{ color: namiBrightBlue }} />;
    }
    return ItemIconMap[key];
  }
}
