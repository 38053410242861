import React from 'react';

import EmptyState from 'src/components/EmptyState/EmptyState';

type EmptyCampaignPlacementStateProps = {
  page: 'campaigns' | 'placements';
  searching: boolean;
};

export default function EmptyCampaignPlacementStates({
  page,
  searching,
}: EmptyCampaignPlacementStateProps) {
  return (
    <EmptyState
      icon={
        searching ? (page === 'campaigns' ? 'campaign' : 'placement') : 'add'
      }
      iconSize="large"
      title={
        searching
          ? `No ${page} match your results`
          : `Create a ${page === 'campaigns' ? 'campaign rule' : 'placement'}`
      }
      titleSize="24px"
      link="https://learn.namiml.com/public-docs/campaigns/campaigns"
      linkText="Learn more about campaigns and placements"
    >
      <></>
    </EmptyState>
  );
}
