import React, { useEffect, useMemo, useState } from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import {
  TCarouselContainer,
  TCarouselSlide,
  TCarouselSlidesState,
  TContainer,
} from '../../../api/types/paywallTemplate.types';
import { useAppSelector } from '../../../hooks/redux.hooks';
import { interpolate } from '../../../utils/interpolation';
import { transition } from '../css';
import TemplateComponent from '../TemplateComponent';
import CarouselIndicator from './CarouselIndicator';
import { ContainerWrapper } from './Container';
import HoverTag from './HoverTag';
import TeasedSlide from './TeasedSlide';

type CarouselContainerProps = {
  component: TCarouselContainer;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled(ContainerWrapper)`
  ${transition()}
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ component }) => {
    return css`
      gap: ${component.slideSpacing || 0}px;
    `;
  }}
`;

const EmptySlide = styled.div<{ width: number }>`
  height: 100%;
  width: ${({ width }) => width}px;
`;

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px !important;
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
`;

export default function CarouselContainer({
  component: {
    loopVariable,
    loopSource,
    'ui.name': carouselName,
    ...component
  },
  slides: parsedSlides,
  minSDKVersion,
}: CarouselContainerProps) {
  const slides = useAppSelector((state) => state.paywallBuilder.slides);

  const slideId = slides[carouselName];
  const isValid =
    loopSource &&
    (typeof loopSource === 'string' || Array.isArray(loopSource)) &&
    typeof loopVariable === 'string';
  const [currentSlide, setCurrentSlide] = useState<TCarouselSlide | null>(null);
  const [nextSlide, setNextSlide] = useState<TCarouselSlide | null>(null);
  const [prevSlide, setPrevSlide] = useState<TCarouselSlide | null>(null);

  const slidesArray: TCarouselSlide[] = useMemo(() => {
    if (!isValid) return [];
    if (Array.isArray(loopSource)) return loopSource;
    return parsedSlides ? parsedSlides[loopSource][carouselName] : [];
  }, [isValid, carouselName, parsedSlides, loopSource]);

  useEffect(() => {
    if (!isValid || !slidesArray[0]) {
      setCurrentSlide(null);
      setNextSlide(null);
      setPrevSlide(null);
    } else {
      const currentSlideIndex = slidesArray.findIndex(
        ({ id }) => slideId === id
      );
      if (currentSlideIndex < 0) {
        setCurrentSlide(slidesArray[0]);
        setNextSlide(slidesArray[1] || null);
        setPrevSlide(null);
      } else {
        setCurrentSlide(slidesArray[currentSlideIndex]);
        setNextSlide(slidesArray[currentSlideIndex + 1] || null);
        setPrevSlide(slidesArray[currentSlideIndex - 1] || null);
      }
    }
  }, [isValid, slidesArray, slideId]);

  const parsedSlideComponents = useMemo(() => {
    if (!isValid || currentSlide === null) return [];
    const replacements = { [loopVariable]: currentSlide };
    return interpolate(component.components, replacements).map((child, i) => (
      <TemplateComponent
        key={`${carouselName}-${i}`}
        component={child}
        groupId={null}
        minSDKVersion={minSDKVersion}
      />
    ));
  }, [
    loopVariable,
    component.components,
    isValid,
    currentSlide,
    carouselName,
    minSDKVersion,
  ]);

  const parsedNextSlideComponents = useMemo(() => {
    if (!isValid || nextSlide === null)
      return [
        <EmptySlide
          width={
            component.nextSlidePadding === undefined
              ? 0
              : component.nextSlidePadding
          }
          key="emptyNextSlide"
        />,
      ];
    const replacements = { [loopVariable]: nextSlide };
    return interpolate(component.components, replacements).map((child, i) => (
      <TeasedSlide
        key={`${carouselName}-${i}-next`}
        component={child as TContainer}
        prevSlide={false}
        scale={component.inactiveSlideScale || 0.8}
        padding={
          component.nextSlidePadding === undefined
            ? 0
            : component.nextSlidePadding
        }
        minSDKVersion={minSDKVersion}
      />
    ));
  }, [
    loopVariable,
    component,
    isValid,
    nextSlide,
    carouselName,
    minSDKVersion,
  ]);

  const parsedPrevSlideComponents = useMemo(() => {
    if (!isValid || prevSlide === null)
      return [
        <EmptySlide
          width={
            component.previousSlidePadding === undefined
              ? 0
              : component.previousSlidePadding
          }
          key="emptyPrevSlide"
        />,
      ];
    const replacements = { [loopVariable]: prevSlide };
    return interpolate(component.components, replacements).map((child, i) => (
      <TeasedSlide
        key={`${carouselName}-${i}-prev`}
        component={child as TContainer}
        prevSlide={true}
        scale={component.inactiveSlideScale || 0.8}
        padding={
          component.previousSlidePadding === undefined
            ? 0
            : component.previousSlidePadding
        }
        minSDKVersion={minSDKVersion}
      />
    ));
  }, [
    loopVariable,
    component,
    isValid,
    prevSlide,
    carouselName,
    minSDKVersion,
  ]);

  if (!isValid) return null;
  const showDots = component.showIndicators === false ? false : true;
  const dots = slidesArray.map(({ id }) => {
    if (currentSlide?.id === id && component.activeIndicator)
      return (
        <CarouselIndicator key={id} component={component.activeIndicator} />
      );
    if (component.indicator)
      return <CarouselIndicator key={id} component={component.indicator} />;
    const color =
      currentSlide?.id === id
        ? component.activeIndicatorColor
        : component.indicatorColor;
    return <Dot key={id} color={color} />;
  });
  return (
    <HoverTag
      title={component.namiComponentType ? 'Carousel' : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <ContainerWrapper
        component={{ ...component, direction: 'vertical' }}
        minSDKVersion={minSDKVersion}
      >
        <Wrapper
          component={{ ...component, direction: 'horizontal' }}
          minSDKVersion={minSDKVersion}
        >
          {parsedPrevSlideComponents}
          {parsedSlideComponents}
          {parsedNextSlideComponents}
        </Wrapper>
        {showDots && <DotWrapper>{dots}</DotWrapper>}
      </ContainerWrapper>
    </HoverTag>
  );
}
