import React, { useEffect, useState } from 'react';

import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useLocation } from 'react-router-dom';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import { pathBeginMatches } from 'src/services/helpers';

import { AppContextType } from '../../AppContextProvider';
import MainNavMenu from '../../components/Menus/MainNavMenu';
import { useAppContext } from '../../hooks';

type PageMenuAndHeaderProps = {
  isVisible: boolean;
  desktopCollapsed: boolean;
  mobileMenuOpen: boolean;
  setDesktopMenuCollapsed: (value: boolean) => void;
  setMobileMenuOpen: (value: boolean) => void;
};

export type NamiMenuItem = {
  name: string;
  path?: string;
  iconName: string;
  enabled: boolean;
  children?: NamiMenuItem[];
};

export default function NavigationFramework({
  isVisible,
  mobileMenuOpen,
  desktopCollapsed,
  setDesktopMenuCollapsed,
  setMobileMenuOpen,
}: PageMenuAndHeaderProps) {
  const context: AppContextType = useAppContext();
  const menuItemMeta: NamiMenuItem[] = [
    {
      name: 'Overview',
      path: '/overview/',
      iconName: 'overview',
      enabled: context.userHasEntitlement('overview.list'),
    },
    {
      name: 'Insights',
      path: '/insights/revenue/',
      iconName: 'insights',
      enabled: context.userHasEntitlement('analytics.list'),
    },
    {
      name: 'Campaigns',
      iconName: 'campaign',
      enabled:
        context.userHasEntitlement('app.campaign.list') ||
        context.userHasEntitlement('app.campaign.legacy.list'),
      children: [
        {
          name: 'Placements',
          path: '/campaigns/placements/',
          iconName: 'campaign',
          enabled: context.userHasEntitlement('app.campaign.list'),
        },
        {
          name: 'Campaign Rules',
          path: '/campaigns/rules/',
          iconName: 'campaign',
          enabled: context.userHasEntitlement('app.campaign.list'),
        },
        {
          name: 'Legacy Campaigns',
          path: '/legacy/campaigns',
          iconName: 'legacy',
          enabled: context.userHasEntitlement('app.campaign.legacy.list'),
        },
      ],
    },
    {
      name: 'Paywalls',
      path: '/paywalls/',
      iconName: 'paywall',
      enabled: context.userHasEntitlement('app.paywall.list'),
    },
    {
      name: 'Products',
      iconName: 'products',
      enabled:
        context.userHasEntitlement('app.productsku.list') ||
        context.userHasEntitlement('app.entitlement.list'),
      children: [
        {
          name: 'Products',
          path: '/products/',
          iconName: 'products',
          enabled: context.userHasEntitlement('app.productsku.list'),
        },
        {
          name: 'Entitlements',
          path: '/entitlements/',
          iconName: 'entitlement',
          enabled: context.userHasEntitlement('app.entitlement.list'),
        },
      ],
    },
    {
      name: 'Customers',
      path: '/customers/',
      iconName: 'crm',
      enabled: context.userHasEntitlement('app.user.list'),
    },
    {
      name: 'Events Feed',
      path: '/developer/',
      iconName: 'sdk',
      enabled: context.userHasEntitlement('app.event.list'),
    },
    {
      name: 'Settings',
      iconName: 'app settings',
      enabled:
        context.userHasEntitlement('app.get') ||
        context.userHasEntitlement('app.platform.list'),
      children: [
        {
          name: 'App Settings',
          path: '/app-settings/',
          iconName: 'app settings',
          enabled: context.userHasEntitlement('app.get'),
        },
        {
          name: 'Integrations',
          path: '/integrations/',
          iconName: 'integration',
          enabled: context.userHasEntitlement('app.platform.list'),
        },
      ],
    },
  ];
  const location = useLocation();
  const [selectedMenuItemKey, setSelectedMenuItemKey] =
    useState<string>('/overview/');

  const menuItems = menuItemMeta.reduce((output, item) => {
    if (item.enabled) {
      const children = (item.children || []).reduce((output, child) => {
        if (child.enabled) {
          return [
            ...output,
            {
              key: child.path,
              label: child.name,
            } as ItemType,
          ];
        }
        return output;
      }, [] as ItemType[]);

      return [
        ...output,
        {
          key: item.path || item.iconName,
          icon: <NamiIcon icon={item.iconName} color="navy" />,
          label: item.name,
          children: children.length ? children : undefined,
        },
      ];
    }
    return output;
  }, [] as ItemType[]);

  useEffect(() => {
    menuItemMeta.forEach((item) => {
      if (item.path === location.pathname) {
        setSelectedMenuItemKey(item.path);
      } else if (item.path && pathBeginMatches(location.pathname, item.path)) {
        setSelectedMenuItemKey(item.path);
      } else if (item.children) {
        for (let child of item.children) {
          if (child.path) {
            if (child.path === location.pathname) {
              setSelectedMenuItemKey(child.path);
              break;
            } else if (
              child.path === '/campaigns/placements' &&
              pathBeginMatches(location.pathname, child.path)
            ) {
              setSelectedMenuItemKey(child.path);
            }
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {isVisible && (
        <MainNavMenu
          items={menuItems}
          isCollapsedDesktop={desktopCollapsed}
          setDesktopMenuCollapsed={setDesktopMenuCollapsed}
          isOpenMobile={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          selectedMenuItemKey={selectedMenuItemKey}
        />
      )}
    </>
  );
}
