import React, { useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Modal, Radio, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { TCarouselFieldType } from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { toCamelCase } from 'src/utils/string';

type AddCarouselFieldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (label: string, type: TCarouselFieldType, value: any) => void;
};

type TCarouselFieldPayload = {
  name: string;
  defaultValue: string;
  type: TCarouselFieldType;
};

const selector = createSelector(
  [({ paywallBuilder: { paywall } }: RootState) => paywall],
  (paywall) => ({
    currentProductVariables:
      paywall!.template['ui.productSettings']?.variablesList,
  })
);

export default function AddCarouselFieldModal({
  isOpen,
  onClose,
  onSubmit,
}: AddCarouselFieldModalProps) {
  const [form] = Form.useForm<TCarouselFieldPayload>();
  const { currentProductVariables } = useAppSelector(selector);
  const [selectedType, setSelectedType] = useState<TCarouselFieldType>('text');

  function uniqueFieldName(newName: string): boolean {
    const nameFormatted = toCamelCase(newName);
    const productNameFormatted = toCamelCase(`${newName}`);
    const result = (currentProductVariables || []).reduce((prev, key) => {
      if (key.variable.toLowerCase() === nameFormatted.toLowerCase())
        return false;
      if (key.variable.toLowerCase() === productNameFormatted.toLowerCase())
        return false;
      return prev;
    }, true);

    return result;
  }

  return (
    <Modal
      title="Add a New Carousel Field"
      open={isOpen}
      footer={null}
      centered
      onCancel={onClose}
      zIndex={1006}
      forceRender
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{ type: selectedType }}
      >
        <>
          <Row gutter={16}>
            <Col xs={18}>
              <FormItem
                label="Field Name"
                required
                tooltip="Give this carousel field a useful name such as 'Slide Subtext' or 'Trial User Title Text'"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Provide a value',
                  },
                  () => {
                    return {
                      message:
                        'Please enter a value without special characters',
                      validator: (_, value) => {
                        const isValid = /^[a-zA-Z0-9-_\s]+$/g.test(value);
                        return isValid ? Promise.resolve() : Promise.reject();
                      },
                    };
                  },
                  () => {
                    return {
                      message: 'This Field Name is already in use.',
                      validator: (_, value) => {
                        const isValid = uniqueFieldName(value);
                        return isValid ? Promise.resolve() : Promise.reject();
                      },
                    };
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={18}>
              <FormItem
                label="Field Type"
                required
                tooltip="Each slide can have a number of text values or images associated"
                name="type"
                rules={[
                  {
                    required: true,
                    message: 'Provide a value',
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) =>
                    setSelectedType(e.target.value as TCarouselFieldType)
                  }
                  options={[
                    {
                      value: 'text',
                      label: 'Text',
                    },
                    {
                      value: 'image',
                      label: 'Image',
                    },
                    {
                      value: 'legal',
                      label: 'Legal',
                    },
                  ]}
                  value={selectedType}
                  optionType="button"
                />
              </FormItem>
            </Col>
          </Row>
          {selectedType === 'text' && (
            <Row>
              <Col xs={18}>
                <FormItem
                  label="Default Field Value"
                  required
                  name="defaultValue"
                  tooltip="This value will be used for all newly created slides."
                  rules={[
                    {
                      required: true,
                      message: 'Provide a value',
                    },
                  ]}
                >
                  <Input maxLength={100} />
                </FormItem>
              </Col>
            </Row>
          )}
          {selectedType === 'legal' && (
            <Row>
              <Col xs={18}>
                <FormItem
                  label="Default Field Value"
                  required
                  name="defaultValue"
                  tooltip="This value will be used for all newly created slides."
                  rules={[
                    {
                      required: true,
                      message: 'Provide a value',
                    },
                  ]}
                >
                  <Input.TextArea />
                </FormItem>
              </Col>
            </Row>
          )}
          <Button
            htmlType="submit"
            type="primary"
            disabled={form.getFieldsError().some(({ errors }) => errors.length)}
            onClick={() => {
              form
                .validateFields()
                .then(() =>
                  onSubmit(
                    form.getFieldValue('name'),
                    form.getFieldValue('type'),
                    form.getFieldValue('defaultValue') || false
                  )
                );
            }}
          >
            Add
          </Button>
        </>
      </Form>
    </Modal>
  );
}
