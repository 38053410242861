import React from 'react';

import Icon, {
  AppstoreAddOutlined,
  SettingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Logout } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Image,
  List,
  Row,
  Space,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useHistory } from 'react-router-dom';
import { defaultImage } from 'src/services/helpers';
import { namiAliceGray, namiSmoke } from 'src/variables';
import styled, { css } from 'styled-components';

import { AppType } from '../../api/types/app.types';
import { AppContextType } from '../../AppContextProvider';
import { useAppContext } from '../../hooks';
import { useAppSelector } from '../../hooks/redux.hooks';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';

type AppSwitchModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onAppClick: (appId: string) => void;
};

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

const StyledCard = styled(Card)`
  .ant-card-meta {
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
`;

const SelectableRow = styled(Row)`
  padding: 4px;
  &:hover {
    background-color: ${namiAliceGray};
  }
`;

const ListItem = styled(List.Item)<{ selected: boolean }>`
  padding: 8px;
  ${({ selected }) => {
    if (selected) {
      return css`
        background-color: ${namiSmoke};
      `;
    }
    return css``;
  }}
`;

export default function MobileAppSwitchModal({
  isVisible,
  onClose,
  onAppClick,
}: AppSwitchModalProps) {
  const context: AppContextType = useAppContext();
  const history = useHistory();
  const { currentApp, apps } = useAppSelector(({ root }) => ({
    currentApp: root.currentApp,
    apps: root.apps as AppType[], // TODO: Remove when "root" is properly typed
  }));
  const user = context.user;
  const image =
    user?.avatar ||
    defaultImage(
      user?.first_name && user?.last_name
        ? `${user.first_name}+${user.last_name}`
        : user?.first_name || ''
    );

  const orgName = formatOrgName(context.selectedOrg?.name || '');

  const accountActions = [
    <Button
      icon={<SettingOutlined />}
      size="small"
      type="text"
      onClick={() => {
        onClose();
        history.push('/settings/?tab=account');
      }}
    />,
    <Button
      icon={<Logout />}
      size="small"
      type="text"
      onClick={() => context.performLogout()}
    />,
  ];

  return (
    <Drawer
      open={isVisible}
      maskClosable={true}
      closable={false}
      onClose={(_e) => onClose()}
      width={260}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <StyledCard
          size="small"
          actions={accountActions}
          style={{ marginBottom: 8 }}
        >
          <Meta
            avatar={<Avatar src={image} size="large" />}
            title={`${user?.first_name} ${user?.last_name}`}
          />
        </StyledCard>
        <StyledTitle>Org</StyledTitle>
        <SelectableRow gutter={6}>
          <Col flex="24px">
            <Avatar src={defaultImage(orgName)} size="small" />
          </Col>
          <Col
            flex="auto"
            onClick={() => {
              onClose();
              history.push('/settings/?tab=org');
            }}
          >
            {orgName}
          </Col>
          <Col flex="24px">
            <Button
              icon={<SyncOutlined />}
              size="small"
              type="text"
              onClick={() => {
                onClose();
                history.push('/settings/?tab=all-orgs');
              }}
            />
          </Col>
        </SelectableRow>
        <List
          size="small"
          dataSource={apps}
          style={{ marginTop: 8 }}
          header={<StyledTitle>Apps</StyledTitle>}
          footer={
            <Button
              type="text"
              icon={<AppstoreAddOutlined />}
              onClick={() => {
                onClose();
                history.push('/settings/?tab=apps');
              }}
            >
              Manage Apps
            </Button>
          }
          renderItem={(app) => (
            <ListItem key={app.id} selected={currentApp?.id === app.id}>
              <Space
                direction="horizontal"
                size={8}
                onClick={() => onAppClick(app.id)}
              >
                <Image
                  height={18}
                  width={18}
                  style={{
                    cursor: 'pointer',
                    borderRadius: 4,
                    transform: 'translateY(-2px)',
                  }}
                  preview={false}
                  src={app.logo || defaultImage(app.name)}
                />
                {app.name}
              </Space>
            </ListItem>
          )}
        />
        <Button block={true} href="https://learn.namiml.com" target="_blank">
          <Space direction="horizontal">
            <Icon
              component={HelpIcon}
              style={{ fontSize: 18, transform: 'translateY(2px)' }}
            />
            Help Center
          </Space>
        </Button>
      </Space>
    </Drawer>
  );

  function formatOrgName(name: string): string {
    const maxChar = 25;
    if (!(name.length && name.length > maxChar)) return name;
    return name.substring(0, maxChar - 3) + '...';
  }
}
