import React, { useEffect, useState } from 'react';

import { Content } from 'antd/lib/layout/layout';
import { Helmet } from 'react-helmet';

import { useAppContext, usePostRenderEffect } from '../../hooks';
import { useAppSelector } from '../../hooks/redux.hooks';
import SegmentClient from '../../segment';
import { capitalizePhrase } from '../../utils/string';

export type PageProps = {
  title: string;
  layout?: PageLayoutType;
  contentContainerClass?: string;
  children: React.ReactNode;
};

type PageContextType = {
  setTitle: (title: string) => void;
};

type PageLayoutType = 'base' | 'full';

const DEFAULT_TITLE = 'Control Center';

// noinspection JSUnusedLocalSymbols
export const PageContext = React.createContext<PageContextType>({
  setTitle: (title: string) => {},
});

export default function Page({
  title: titleProp,
  contentContainerClass = 'nami-layout-content',
  children,
}: PageProps) {
  const app = useAppSelector(({ root }) => root.currentApp);
  const { selectedOrg: org } = useAppContext();
  const [title, setTitle] = useState(titleProp);

  usePostRenderEffect(() => setTitle(titleProp), [titleProp]);
  useEffect(() => {
    if (!(app && org)) return;
    SegmentClient.trackPage(title, {
      app: { id: app.id, name: app.name },
      org: { id: org.id, name: org.name },
    });
  }, [title, app, org]);

  const displayTitle = title
    ? capitalizePhrase(title.replace(/[-_]/g, ' '))
    : DEFAULT_TITLE;

  return (
    <Content className={contentContainerClass}>
      <PageContext.Provider value={{ setTitle }}>
        <Helmet defaultTitle={DEFAULT_TITLE}>
          <meta charSet="utf-8" />
          <title>Nami - {displayTitle}</title>
        </Helmet>
        {children}
      </PageContext.Provider>
    </Content>
  );
}
