import React from 'react';

import { Button, Col, Form, Switch } from 'antd';
import {
  TComponent,
  TConditionalComponent,
} from 'src/api/types/paywallTemplate.types';
import { useActions } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled from 'styled-components';

import InputLabel from './InputLabel';

type SelectProductActiveConditionProps = {
  component: TConditionalComponent;
  assertionIndex: number;
};

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  padding: 0px !important;
`;

export default function SelectedProductActiveCondition({
  component,
  assertionIndex,
}: SelectProductActiveConditionProps) {
  const actions = useActions(PaywallBuilderSlice.actions);

  if (componentConditionalContainsActiveAttribute(component, assertionIndex)) {
    return (
      <Col xs={24} md={24} key={`active${assertionIndex}`}>
        <StyledFormItem
          label={
            <InputLabel
              name={'Radio Active'}
              collapsible={false}
              description={null}
            />
          }
        >
          <Switch checked={true} disabled={true} size="small" />
        </StyledFormItem>
      </Col>
    );
  }

  return <></>;
}

export function componentConditionalContainsActiveAttribute(
  component: TComponent,
  assertionIndex: number
): boolean {
  if (
    component.conditionAttributes?.length &&
    component.conditionAttributes[assertionIndex]
  ) {
    const attributes = component.conditionAttributes[assertionIndex].attributes;
    return Object.keys(attributes).includes('active');
  }
  return false;
}
