import React, { useEffect, useMemo, useState } from 'react';

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftOutlined,
  LockFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  MenuProps,
  Row,
  Space,
  Tabs,
  Tooltip,
} from 'antd';
import {
  TAllComponentDetailValues,
  TCollapseContainer,
  TComponent,
  TComponentLocation,
  TConditionalComponent,
  TFormSection,
  TProductContainer,
  TRepeatingList,
} from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import BuilderTreeWebPaywall from 'src/components/WebPaywalls/BuilderTreeWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import {
  findCollapseComponentParent,
  interpolate,
} from 'src/utils/interpolation';
import {
  deconstructVariable,
  findComponentFromLocation,
  parseLocationString,
} from 'src/utils/paywall';
import { toSlug } from 'src/utils/string';
import { namiBrightBlue, namiDarkGray, namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import {
  findParentRepeatingGridLocation,
  findProductContainerChildIds,
  generateFormSectionsByComponent,
} from '../../utils/componentGeneration';
import { buildMediaVariables } from '../../utils/variables';
import PaywallBuilderDrawerRight from '../PaywallBuilderDrawerRight';
import PaywallBuilderDrawerTitle from '../PaywallBuilderDrawerTitle';
import { allNewComponentOptions } from './AddComponentPopover';
import { FormSection } from './FormSection';
import BuyPromoCondition, {
  componentConditionalContainsOnTapAttribute,
} from './inputs/BuyPromoCondition';
import ConditionSelector from './inputs/ConditionSelector';
import ConditionSelectorFooter from './inputs/ConditionSelectorFooter';
import OptionalPropertySelector from './inputs/OptionalPropertySelector';
import ProductDataSourceSelector from './inputs/ProductDataSourceSelector';
import RepeatingGridDataSourceSelector from './inputs/RepeatingGridDataSourceSelector';
import SafeAreaApplication from './inputs/SafeAreaApplication';
import SelectedProductActiveCondition, {
  componentConditionalContainsActiveAttribute,
} from './inputs/SelectedProductActiveCondition';

type ComponentEditorProps = {
  componentId: string | null;
  parsedLocation: TComponentLocation | null;
  component: TComponent | null;
};

const restrictedDeletionIds: string[] = [
  'root',
  'contentContainer',
  'contentContainer0', //TODO - better fix
  'backgroundContainer',
  'backgroundContainer0', //TODO - better fix
  'productButton',
  'productButton0',
  'productButton1',
  'carouselSlide',
];
const restrictedDeleteTypes: TAllComponentDetailValues[] = [
  'productGroupToggleItem',
  'collapseBody',
  'collapseHeader',
  'stack',
  'navigatePageButton',
  'slide',
];
const restrictedDuplicateIds: string[] = [
  'root',
  'contentContainer',
  'contentContainer0', //TODO - better fix
  'header',
  'header0',
  'footer',
  'backgroundContainer',
  'backgroundContainer0', //TODO - better fix
  'productButton',
  'productButton0',
  'productButton1',
  'carouselSlide',
];
const restrictedDuplicateTypes: TAllComponentDetailValues[] = [
  'productGroupToggleItem',
  'collapseBody',
  'collapseHeader',
  'carousel',
  'slide',
  'stack',
  'navigatePageButton',
  'playPauseButton',
  'volumeButton',
  'radio',
];
const restrictedHideIds: string[] = [
  'root',
  'contentContainer',
  'contentContainer0', //TODO - better fix
  'header',
  'header0',
  'footer',
  'slide',
  'backgroundContainer',
  'backgroundContainer0', //TODO - better fix
];
const resrictedHideTypes: TAllComponentDetailValues[] = [
  'productContainer',
  'productButton',
  'productGroupToggleItem',
  'collapseBody',
  'collapseHeader',
  'slide',
  'stack',
];

const variableSelector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template.variables!,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template.initialState!,
    ({ paywallBuilder: { mediaList } }: RootState) => mediaList,
    ({ paywallBuilder }: RootState) => paywallBuilder.groupId,
  ],
  (variables, state, mediaList): { [key: string]: any } => ({
    var: variables,
    state,
    media: buildMediaVariables(mediaList),
  })
);

const TitleIcon = styled.span`
  svg {
    width: 14px;
    height: 14px;
    fill: ${namiDarkGray};
    transform: translateY(3px);
  }
`;
export const TitleInput = styled(Input)`
  font-size: 13px;
  transform: translateY(2px);
  svg {
    visibility: hidden;
  }

  &:hover {
    border-bottom: 1.5px solid ${namiDarkGray} !important;
    svg {
      visibility: visible;
    }
  }

  .ant-input[disabled] {
    color: black;
  }
  .ant-input-disabled {
    color: black;
  }
`;

export default function ComponentEditor({
  componentId,
  component,
  parsedLocation,
}: ComponentEditorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const appContext = useAppContext();
  const {
    template,
    groupId,
    menuTabItem,
    idLocations,
    idTypeLocations,
    formFactor,
    capabilities,
    displaySingleGroup,
    groups,
  } = useAppSelector(({ paywallBuilder }) => {
    return {
      template: paywallBuilder.paywall?.template,
      groupId: paywallBuilder.groupId,
      menuTabItem: paywallBuilder.editingComponentEditorTabItem,
      idLocations: paywallBuilder.idLocations,
      idTypeLocations: paywallBuilder.idTypeLocations,
      formFactor: paywallBuilder.formFactor,
      capabilities: paywallBuilder.paywall?.template['ui.capabilities'] || [],
      displaySingleGroup:
        paywallBuilder.paywall?.template['ui.displaySingleGroup'],
      groups: paywallBuilder.productGroups,
    };
  });
  const productChildren = useMemo(() => {
    return findProductContainerChildIds(idLocations, idTypeLocations);
  }, [idLocations, idTypeLocations]);
  const parentRepeatingGrid = useMemo(() => {
    if (!componentId) return null;
    const parentLoc = findParentRepeatingGridLocation(
      componentId,
      idLocations,
      idTypeLocations
    );
    if (!parentLoc) return null;
    const parsedRepeatingGridLocation = parseLocationString(parentLoc);
    return findComponentFromLocation(
      template?.pages || [],
      parsedRepeatingGridLocation
    );
  }, [template?.pages, componentId, idLocations, idTypeLocations]);

  const parentDataSourceRoot = useMemo(() => {
    if (!parentRepeatingGrid) return null;
    return deconstructVariable(
      ((parentRepeatingGrid as TRepeatingList).loopSource as string) || '',
      false,
      true
    );
  }, [parentRepeatingGrid]);

  const productGroupCarousel = useMemo(() => {
    return capabilities.includes('product_group_carousel');
  }, [capabilities]);

  const rawVariables = useAppSelector(variableSelector);
  const variables = useMemo(() => {
    return {
      ...rawVariables,
      state: {
        ...rawVariables.state,
        currentGroupId: groupId,
        groups: {
          0: groups[0],
        },
      },
    };
  }, [rawVariables, groupId, groups]);

  const [
    builderTreePaywallOpen,
    openBuilderTreePaywall,
    closeBuilderTreePaywall,
  ] = useBooleanState(false);
  const [componentTitle, setComponentTitle] = useState<string>('');

  let title: string = '';
  let productChild: boolean = false;
  let childOfCollapseComponent: TCollapseContainer | undefined = undefined;

  if (parsedLocation?.pos.includes('collapseHeader')) {
    childOfCollapseComponent = findCollapseComponentParent(
      parsedLocation,
      template?.pages || []
    );
  }

  useEffect(() => {
    setComponentTitle(component?.title || 'Body');
  }, [component]);

  const drawerTitleEditor = useMemo(() => {
    return (
      <Tooltip title="Edit component name" placement="bottom">
        <TitleInput
          value={componentTitle}
          size="small"
          bordered={false}
          onChange={(e) => setComponentTitle(e.target.value)}
          onPressEnter={(_e) => updateTitle()}
          disabled={componentId === 'root'}
          suffix={
            <EditOutlined style={{ color: namiDarkGray, fontSize: 13 }} />
          }
        />
      </Tooltip>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentTitle]);

  const allFormSections = useMemo(() => {
    if (!component || !parsedLocation) return null;
    return generateFormSectionsByComponent(
      component,
      parsedLocation,
      parentDataSourceRoot,
      productGroupCarousel
    );
  }, [component, parsedLocation, parentDataSourceRoot, productGroupCarousel]);

  const nonConditionalFormSections = useMemo(() => {
    if (!allFormSections || !allFormSections.sections) return [];
    return interpolate(
      allFormSections.sections,
      interpolate(variables, variables)
    );
  }, [allFormSections, variables]);

  const conditionalFormSections = useMemo(() => {
    if (!allFormSections || !allFormSections.conditions) return [];
    return allFormSections.conditions;
  }, [allFormSections]);

  const reversedIndexMap = useMemo(() => {
    return conditionalFormSections.reduce((output, _section, index) => {
      return [index, ...output];
    }, [] as Array<number>);
  }, [conditionalFormSections]);

  if (!template || !componentId) return null;

  productChild = productChildren.includes(componentId);

  let dropdownItems: MenuProps['items'] = [];
  if (canCopy(component)) {
    dropdownItems.push({
      label: (
        <Space direction="horizontal">
          <CopyOutlined style={{ fontSize: 12 }} />
          Copy
        </Space>
      ),
      key: 'copy',
      disabled: !appContext.userHasEntitlement('app.paywall.component.create'),
      onClick: () => {
        actions.duplicateComponent({ component: component });
      },
    });
  }
  if (canHide(component)) {
    dropdownItems.push({
      label: (
        <Space direction="horizontal">
          {!appContext.userHasEntitlement('app.paywall.component.hide') ? (
            <LockFilled style={{ fontSize: 12, color: namiBrightBlue }} />
          ) : component?.hidden ? (
            <EyeOutlined style={{ fontSize: 12 }} />
          ) : (
            <EyeInvisibleOutlined style={{ fontSize: 12 }} />
          )}
          {component?.hidden ? 'Show' : 'Hide'}
        </Space>
      ),
      key: 'hide',
      onClick: () => {
        if (appContext.userHasEntitlement('app.paywall.component.hide')) {
          actions.toggleComponentHidden({
            component: component,
            hidden: !component?.hidden,
          });
        } else {
          openBuilderTreePaywall();
        }
      },
    });
  }

  if (canDelete(component)) {
    dropdownItems.push({
      label: (
        <Space direction="horizontal">
          <DeleteOutlined style={{ fontSize: 12 }} />
          Delete
        </Space>
      ),
      key: 'delete',
      disabled: !appContext.userHasEntitlement('app.paywall.component.delete'),
      onClick: () => {
        deleteComponent();
      },
    });
  }

  dropdownItems.push({
    label: (
      <a href={generateComponentDocsLink()} target="_blank" rel="noreferrer">
        <Space direction="horizontal">
          <QuestionCircleOutlined style={{ fontSize: 12 }} />
          Docs
        </Space>
      </a>
    ),
    key: 'docs',
  });

  const drawerTitle: React.ReactNode = (
    <Row gutter={[0, 0]} style={{ width: '100%' }}>
      {component &&
        component.namiComponentType &&
        allNewComponentOptions.hasOwnProperty(component!.namiComponentType) && (
          <Col flex="18px">
            <Tooltip
              title={
                allNewComponentOptions[component!.namiComponentType].title || ''
              }
              placement="bottom"
            >
              <TitleIcon>{generateComponentIcon()}</TitleIcon>
            </Tooltip>
          </Col>
        )}
      <Col flex="auto" style={{ marginRight: 6 }}>
        {drawerTitleEditor}
      </Col>
      <Col flex="42px" style={{ textAlign: 'right' }}>
        <Dropdown menu={{ items: dropdownItems }} overlayStyle={{ width: 120 }}>
          <Button type="text" size="small" icon={<EllipsisOutlined />} />
        </Dropdown>
      </Col>
    </Row>
  );

  let tabItems = [];

  if (component) {
    tabItems.push({
      label: (
        <Space direction="horizontal" size={4}>
          Conditions
          {conditionalFormSections.length > 0 &&
            `(${conditionalFormSections.length})`}
        </Space>
      ),
      key: 'conditions',
      children: (
        <Form layout="vertical">
          <Row gutter={16}>
            {component &&
              parsedLocation &&
              ((component as TConditionalComponent).assertions ||
                (component as TConditionalComponent).orAssertions) && (
                <FormSection
                  key="conditionVisibility"
                  collapseIntercomPrefix="intercom"
                  variables={variables}
                  isSingle={true}
                  title="Visibility"
                  collapsible={true}
                  displayVar={null}
                  hint={null}
                  extraContentBelowHeader={
                    <ConditionSelector
                      component={component as TConditionalComponent}
                      componentLocation={parsedLocation}
                      variant="conditionalComponent"
                      assertionsGroup={[]} //Not needed
                      assertionIndex={0} //Not used
                      productChild={productChild}
                      childOfCollapseComponent={childOfCollapseComponent}
                      parentRepeatingGrid={parentRepeatingGrid}
                      type={
                        (component as TConditionalComponent).orAssertions
                          ? 'or'
                          : 'and'
                      }
                    />
                  }
                  fields={[]}
                  onChange={() => {
                    return;
                  }}
                />
              )}
            {parsedLocation &&
              [...conditionalFormSections].reverse().map((section, index) => (
                <FormSection
                  key={`condition${index}`}
                  collapseIntercomPrefix="intercom"
                  variables={variables}
                  isSingle={false}
                  title="Conditional Styling"
                  collapsible={true}
                  displayVar={null}
                  hint={null}
                  hideCollapseIcon={true}
                  currentAssertionIndex={reversedIndexMap[index]}
                  component={component}
                  extraCollapseHeaderContent={
                    <Space direction="horizontal" size={5}>
                      {conditionalFormSections.length > 1 && (
                        <Space direction="horizontal" size={0}>
                          <Tooltip
                            title={'Prioritize conditional'}
                            placement="topRight"
                            align={{ offset: [8, 6] }}
                          >
                            <IconActionButton
                              type="text"
                              icon={
                                <ArrowUpOutlined style={{ fontSize: '12px' }} />
                              }
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                moveCondition(
                                  parsedLocation,
                                  reversedIndexMap[index],
                                  false
                                );
                              }}
                              disabled={index === 0}
                            />
                          </Tooltip>
                          <Tooltip
                            title={'Deprioritize conditional'}
                            placement="topRight"
                            align={{ offset: [8, 6] }}
                          >
                            <IconActionButton
                              type="text"
                              icon={
                                <ArrowDownOutlined
                                  style={{ fontSize: '12px' }}
                                />
                              }
                              size="small"
                              onClick={(event) => {
                                event.stopPropagation();
                                moveCondition(
                                  parsedLocation,
                                  reversedIndexMap[index],
                                  true
                                );
                              }}
                              disabled={
                                index === conditionalFormSections.length - 1
                              }
                            />
                          </Tooltip>
                        </Space>
                      )}
                      <Tooltip
                        title={'Remove condition block'}
                        placement="topRight"
                        align={{ offset: [8, 6] }}
                      >
                        <IconActionButton
                          type="text"
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            onRemoveConditions(
                              parsedLocation,
                              reversedIndexMap[index]
                            );
                          }}
                          disabled={
                            componentConditionalContainsOnTapAttribute(
                              component,
                              reversedIndexMap[index]
                            ) ||
                            componentConditionalContainsActiveAttribute(
                              component,
                              reversedIndexMap[index]
                            )
                          }
                          icon={<CloseOutlined style={{ fontSize: '12px' }} />}
                          style={{ color: namiMediumGray }}
                        />
                      </Tooltip>
                    </Space>
                  }
                  extraContentBelowHeader={
                    <ConditionSelector
                      component={component as TConditionalComponent}
                      componentLocation={parsedLocation}
                      variant="conditionAttributes"
                      assertionsGroup={section.assertions}
                      assertionIndex={reversedIndexMap[index]}
                      productChild={productChild}
                      childOfCollapseComponent={childOfCollapseComponent}
                      parentRepeatingGrid={parentRepeatingGrid}
                      type={section.type}
                    />
                  }
                  extraContentAtBottom={
                    <>
                      <BuyPromoCondition
                        component={component as TConditionalComponent}
                        assertionIndex={reversedIndexMap[index]}
                      />
                      <SelectedProductActiveCondition
                        component={component as TConditionalComponent}
                        assertionIndex={reversedIndexMap[index]}
                      />
                      <ConditionSelectorFooter
                        component={component as TConditionalComponent}
                        componentLocation={parsedLocation}
                        assertionIndex={reversedIndexMap[index]}
                      />
                    </>
                  }
                  onChange={(variable, value) => {
                    actions.setTemplateVariable({ key: variable, value });
                  }}
                  fields={section.fields}
                />
              ))}
            {component &&
              parsedLocation &&
              !(component as TConditionalComponent).assertions &&
              !(component as TConditionalComponent).orAssertions && (
                <Col
                  xs={24}
                  key={'emptyConditionals'}
                  style={{ marginTop: 12, marginBottom: 12 }}
                >
                  <Button onClick={() => addConditionalAttributes()}>
                    Add Condition
                  </Button>
                </Col>
              )}
          </Row>
        </Form>
      ),
    });
  }

  if (component && component.component !== 'condition') {
    tabItems.unshift({
      label: <>Appearance</>,
      key: 'appearance',
      children: (
        <Form layout="vertical">
          <Row gutter={16}>
            {nonConditionalFormSections.map((section) => (
              <FormSection
                key={section.title}
                collapseIntercomPrefix="intercom"
                variables={variables}
                isSingle={isSingle}
                onChange={(variable, value) =>
                  actions.setTemplateVariable({ key: variable, value })
                }
                component={component}
                {...section}
                extraContentAtBottom={
                  <>
                    {getDataSourceSelector(section)}
                    {getSafeArea(section)}
                  </>
                }
              />
            ))}
            <OptionalPropertySelector
              component={component}
              componentLocation={parsedLocation}
            />
          </Row>
        </Form>
      ),
    });
  }

  function getSafeArea(section: TFormSection): React.ReactNode | undefined {
    if (
      parsedLocation &&
      formFactor !== 'television' &&
      formFactor !== 'desktop' &&
      section.title === 'Spacing'
    ) {
      return (
        <SafeAreaApplication
          component={component as TProductContainer}
          componentLocation={parsedLocation}
        />
      );
    }
    return undefined;
  }

  function getDataSourceSelector(
    section: TFormSection
  ): React.ReactNode | undefined {
    const repeatingTypes: TAllComponentDetailValues[] = [
      'repeatingList',
      'repeatingImageSource',
      'repeatingTextSource',
    ];
    if (section.title === 'Content') {
      if (component?.component === 'productContainer') {
        return (
          <ProductDataSourceSelector
            component={component as TProductContainer}
            componentLocation={parsedLocation}
          />
        );
      } else if (
        component?.namiComponentType &&
        repeatingTypes.includes(component.namiComponentType)
      ) {
        return (
          <>
            <RepeatingGridDataSourceSelector
              component={component as TRepeatingList}
              componentLocation={parsedLocation}
              parentRepeatingGrid={parentRepeatingGrid}
            />
            {component.namiComponentType === 'repeatingList' && (
              <ConditionSelector
                component={component as TConditionalComponent}
                componentLocation={parsedLocation}
                variant="loopSource"
                assertionsGroup={[]} //Not needed
                assertionIndex={0} //Not used
                productChild={productChild}
                childOfCollapseComponent={childOfCollapseComponent}
                parentRepeatingGrid={parentRepeatingGrid}
                type={'and'}
              />
            )}
          </>
        );
      }
    }
    return undefined;
  }

  const isSingle = nonConditionalFormSections.length === 1;
  return (
    <>
      <PaywallBuilderDrawerRight
        title={
          <PaywallBuilderDrawerTitle>{drawerTitle}</PaywallBuilderDrawerTitle>
        }
        onClose={() => {
          actions.setEditingComponentId(null);
          actions.setSelectedTreeKey(null);
        }}
        open={!!componentId}
        closeIcon={<LeftOutlined style={{ fontSize: 15 }} />}
        className={
          'intercom-edit-' +
          toSlug(title ? title : 'component') +
          ' verticalScrollDrawer'
        }
      >
        <Tabs
          items={tabItems}
          size="small"
          destroyInactiveTabPane={true}
          activeKey={
            tabItems.length > 1 ? menuTabItem || 'appearance' : 'conditions'
          }
          onChange={(activeKey) =>
            actions.setEditingComponentEditorTabItem(activeKey)
          }
        />
      </PaywallBuilderDrawerRight>
      <BuilderTreeWebPaywall
        visible={builderTreePaywallOpen}
        onCancel={closeBuilderTreePaywall}
      />
    </>
  );

  function canDelete(component: TComponent | null) {
    if (component?.namiComponentType === 'productContainer') {
      return (
        capabilities.includes('multipage') ||
        (capabilities.includes('product_groups') &&
          !displaySingleGroup &&
          idTypeLocations['productContainer'].length > 1)
      );
    }
    if (component?.namiComponentType === 'carousel') {
      return !capabilities.includes('product_group_carousel');
    }
    return (
      component &&
      component.id &&
      !restrictedDeletionIds.includes(component?.id) &&
      !restrictedDeleteTypes.includes(component.namiComponentType || 'group')
    );
  }

  function canCopy(component: TComponent | null) {
    if (component?.namiComponentType === 'productContainer') {
      return (
        capabilities.includes('multipage') ||
        (capabilities.includes('product_groups') && !displaySingleGroup)
      );
    }
    return (
      component &&
      component.id &&
      !restrictedDuplicateIds.includes(component?.id) &&
      !restrictedDuplicateTypes.includes(component.namiComponentType || 'group')
    );
  }

  function canHide(component: TComponent | null) {
    if (component?.namiComponentType === 'carousel') {
      return !capabilities.includes('product_group_carousel');
    }
    return (
      component &&
      component.id &&
      !restrictedHideIds.includes(component?.id) &&
      !resrictedHideTypes.includes(component.namiComponentType || 'group')
    );
  }

  function deleteComponent() {
    if (componentId === 'purchaseButton') {
      actions.removeSelectedCapability();
    } else if (componentId === 'carousel') {
      actions.removeCarousel();
    } else if (componentId === 'productGroupToggle') {
      actions.removeProductGroups();
    } else if (componentId === 'deeplinkButton') {
      actions.removeDeeplink();
    } else if (componentId === 'footer') {
      actions.removeFooter();
    } else if (componentId === 'header') {
      //TODO - handle multiple pages
      actions.removeHeader();
    } else {
      actions.removeComponent({
        componentId: componentId,
        parsedLocation: parsedLocation,
      });
    }
    actions.setEditingComponentId(null);
  }

  function generateComponentIcon(): JSX.Element {
    if (component?.hidden) return <EyeInvisibleOutlined />;
    if (
      component &&
      component.namiComponentType &&
      allNewComponentOptions.hasOwnProperty(component!.namiComponentType)
    ) {
      return allNewComponentOptions[component!.namiComponentType].icon;
    }
    return <></>;
  }

  function generateComponentDocsLink(): string {
    const sectionIds = [
      'header',
      'header0',
      'backgroundContainer',
      'backgroundContainer0',
      'contentContainer',
      'contentContainer0',
      'footer',
    ];
    if (component && component.id && sectionIds.includes(component?.id))
      return 'https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/paywall-layout';
    return 'https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/components';
  }

  function addConditionalAttributes() {
    if (parsedLocation)
      actions.addComponentConditionalAttributeAssertion({
        idLocation: parsedLocation,
        conditionalAttributes: {
          component: 'condition',
          assertions: [],
          attributes: {},
        },
      });
  }

  function updateTitle() {
    if (!componentTitle.length || !parsedLocation) return;
    actions.updateComponentTitle({
      location: parsedLocation,
      value: componentTitle,
    });
  }

  function onRemoveConditions(
    componentLocation: TComponentLocation,
    assertionIndex: number
  ) {
    if (!componentLocation) {
      console.warn(
        `Missing component location for ${
          component!.id
        }, unable to remove conditional block`
      );
      return;
    }
    actions.removeComponentConditionalAttributes({
      idLocation: componentLocation,
      groupIndex: assertionIndex,
    });
  }

  function moveCondition(
    componentLocation: TComponentLocation,
    assertionIndex: number,
    moveUp: boolean = true
  ) {
    actions.reorderComponentConditionalAttribute({
      idLocation: componentLocation,
      groupIndex: assertionIndex,
      moveUp: moveUp,
    });
  }
}
