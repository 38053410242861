import React, { MouseEventHandler } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { namiMediumGray } from 'src/variables';

import { AppContextType } from '../../AppContextProvider';
import { useAppContext } from '../../hooks';
import { useAppSelector } from '../../hooks/redux.hooks';
import { defaultImage } from '../../services/helpers';
import styles from './AppIcon.module.less';

type AppIconProps = { onClick: MouseEventHandler };

export default function AppIcon({ onClick }: AppIconProps) {
  const context: AppContextType = useAppContext();
  const app = useAppSelector(({ root }) => root.currentApp);

  if (!context.selectedOrg) return null;
  if (!app) {
    return (
      <div className={styles.iconBackground} onClick={onClick}>
        <Button type="primary" size="large" className={styles.button}>
          <PlusOutlined
            style={{ height: 20, width: 20, color: namiMediumGray }}
          />
        </Button>
      </div>
    );
  }
  const { logo, name } = app;
  return (
    <span
      onClick={(e) => {
        onClick(e);
      }}
      className={styles.iconBackground}
    >
      <div
        data-cy="app-name"
        className={styles.iconImage}
        style={{
          backgroundImage: `url(${logo || defaultImage(escape(name))})`,
        }}
      />
    </span>
  );
}
