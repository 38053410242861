import React from 'react';

import { useAppContext } from 'src/hooks';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { TOption, formFactorLabelMap, identifierLabel } from './mapsAndOptions';

export default function RequestFormFactorSelect(props: BaseFilterSelectProps) {
  const context = useAppContext();
  const options = Object.entries(formFactorLabelMap).reduce(
    (output, [value, label]) => {
      if (
        value === 'desktop' &&
        !context.userHasEntitlement('app.paywall.formfactor.desktop') //TODO - update entitlement
      )
        return output;
      return [...output, { value, key: value, label: label, text: label }];
    },
    [] as TOption[]
  );

  return (
    <FilterSelect
      {...props}
      allowMultiple={false}
      label={identifierLabel['Request.form_factor']}
      options={options}
      operators={['i_equals_or_null']}
      operator="i_equals_or_null"
      variant="select"
    />
  );
}
