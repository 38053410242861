import React from 'react';

import { Card, Result } from 'antd';

import NamiIcon from '../../components/NamiIcon/NamiIcon';

type EmptyStateProps = {
  title: string;
  titleSize?: string;
  icon?: string;
  iconSize?: 'large';
  link: string;
  linkText: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
};

export default function EmptyState({
  title,
  titleSize = '14px',
  icon,
  iconSize = 'large',
  link,
  linkText,
  children,
  loading,
}: EmptyStateProps) {
  return (
    <Card loading={loading}>
      <Result
        icon={
          icon ? (
            <NamiIcon icon={icon} size={iconSize} color="primary" />
          ) : (
            <></>
          )
        }
        title={<div style={{ fontSize: titleSize }}>{title}</div>}
        style={{ padding: '32px 12px' }}
        extra={
          <>
            <div style={{ marginBottom: 30, marginTop: -20 }}>
              <a href={link} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            </div>
            {children}
          </>
        }
      />
    </Card>
  );
}
