import React from 'react';

import { useCampaignLabelsQuery } from 'src/hooks/queries/campaign.hooks';

import SummaryCard from './SummaryCard';

export default function PlacementsSummaryCard() {
  const placements = useCampaignLabelsQuery({
    pageSize: 20,
    enabled: true,
    archived: false,
  });

  return (
    <SummaryCard
      title="Live Placements"
      loading={placements.isLoading}
      value={placements.data?.count}
      link={'/campaigns/placements/?status=enabled'}
    />
  );
}
