import { client } from './clients';
import { TEventLogItem } from './types/developer.types';
import { TApiOptions, TListResult } from './types/main.types';
import { buildURLParams, extractErrorData, extractResponseData } from './utils';

const developer = {
  getEventLog: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TEventLogItem>> => {
    const params = buildURLParams({
      pageSize: 50,
      ...apiOptions,
      app__id: appId,
      partner: 'log',
      event_type__in: apiOptions.event_type__in || '',
    });
    return client
      .get(`/api/v2/event_log/?${params}&order_by=-created_date`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default developer;
