import React from 'react';

import Icon, { LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Row, Space, Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { useHistory } from 'react-router';
import { ReactComponent as JoinIcon1 } from 'src/images/marketing/JoinIcon1.svg';
import { ReactComponent as JoinIcon2 } from 'src/images/marketing/JoinIcon2.svg';
import { ReactComponent as JoinIcon3 } from 'src/images/marketing/JoinIcon3.svg';
import { ReactComponent as JoinIcon4 } from 'src/images/marketing/JoinIcon4.svg';
import NamiLogo from 'src/images/namiMLlogo.svg';
import {
  namiAqua,
  namiBrightBlue,
  namiLightGray,
  namiNavy,
  namiPureWhite,
} from 'src/variables';
import styled from 'styled-components';

import api from '../../../api';
import Page from '../../../components/Page/Page';
import { usePromiseState, useQueryParams } from '../../../hooks';
import { hasLowerCase, hasNumber, hasUpperCase } from '../../../utils/string';

const StyledFormInput = styled(Form.Item)`
  .ant-form-item-control-input-content .ant-input {
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid ${namiPureWhite};
    color: ${namiPureWhite};
    padding: 8px 12px !important;

    :hover {
      border-color: ${namiAqua};
    }
  }

  .ant-form-item-explain-error {
    color: #ff677e;
  }
`;

const StyledFormPassword = styled(Form.Item)`
  .ant-input-affix-wrapper {
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid ${namiPureWhite};
    color: ${namiPureWhite};

    .ant-input-suffix .ant-input-password-icon.anticon {
      color: ${namiLightGray};
      padding: 8px 12px !important;
    }

    :hover {
      border-color: ${namiAqua};
    }
  }

  .ant-form-item-explain-error {
    color: #ff677e;
  }

  input {
    background-color: transparent;
    color: ${namiPureWhite};
  }
`;

const CreateButton = styled(Button)`
  border-radius: 50px;
  padding: 10px 40px;
  height: 100%;
  span {
    font-weight: 600;
    font-size: 15px;
  }

  :hover {
    background-color: ${namiBrightBlue};
  }
`;

const TermsText = styled(Typography.Text)`
  color: ${namiPureWhite};
  text-align: center;
  font-size: 14px;
  width: 300px;
  display: block;

  a {
    color: ${namiAqua};
  }
`;

const Header1 = styled(Typography.Text)`
  font-size: 44px;
  font-weight: 600;
  color: ${namiNavy};
  text-align: center;
  line-height: normal;
`;

const Header1Light = styled(Typography.Text)`
  font-size: 44px;
  font-weight: 600;
  color: ${namiPureWhite};
  text-align: center;
  line-height: normal;
`;

const Header1BG = styled.span`
  display: inline-block;
  background-color: ${namiNavy};
  margin: 0 10px;
  padding: 0 3px;
`;

const IconSubtext = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 600;
  color: ${namiNavy};
  text-align: center;
`;

const Header2 = styled(Typography.Text)`
  font-size: 24px;
  font-weight: 600;
  color: ${namiNavy};
`;

const Header2Light = styled(Typography.Text)`
  font-size: 24px;
  font-weight: 600;
  color: ${namiPureWhite};
`;

const PASSWORD_RULE: ValidatorRule = {
  validator(_, value: string) {
    const passwordValidators: [(value: string) => boolean, string][] = [
      [(password) => !!password && password !== '', 'Password is required'],
      [hasLowerCase, 'Password needs 1 lower case letter'],
      [hasUpperCase, 'Password needs 1 upper case letter'],
      [hasNumber, 'Password needs 1 number'],
      [
        (password) => password.length > 7,
        'Password should be at least 8 characters long',
      ],
    ];

    for (const [isValid, message] of passwordValidators) {
      if (!isValid(value)) return Promise.reject(new Error(message));
    }
    return Promise.resolve();
  },
};

const JoinPage = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const promoCode = useQueryParams().get('promo_code');

  const {
    isLoading,
    error,
    trigger: signUp,
  } = usePromiseState(() => {
    // TODO: Make this a React-Query mutation
    const data = {
      ...form.getFieldsValue(),
      password_confirm: form.getFieldValue('password'),
      user_create_source: 'not_invitation',
      user_accepts_tos: true,
      opted_in_marketing: true,
      promo_code: promoCode || '',
    };
    return api
      .signUp(data)
      .then(() =>
        history.push(
          encodeURI(
            `/join/confirm/?email_address=${form.getFieldValue('username')}`
          )
        )
      );
  });

  return (
    <Page title="Join" contentContainerClass="full-main-container-white">
      <Row style={{ height: '100vh' }} justify="center" align="middle">
        <Col
          xs={24}
          lg={12}
          style={{
            padding: '4em 10%',
            backgroundColor: namiNavy,
            height: '100vh',
          }}
        >
          <Space
            size={30}
            direction="vertical"
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
              justifyContent: 'center',
            }}
            align="center"
          >
            <Image src={NamiLogo} preview={false} width={200} />
            <Header2Light>Create your account now.</Header2Light>
            <Form
              form={form}
              layout="vertical"
              style={{ width: 300, padding: '16px 0px' }}
              onFinish={signUp}
              requiredMark={false}
              scrollToFirstError
              initialValues={{}}
              wrapperCol={{ span: 24 }}
            >
              <StyledFormInput
                name="username"
                rules={[{ required: true, message: 'This field is required' }]}
                validateStatus={error?.username ? 'error' : ''}
                help={error?.username}
              >
                <Input
                  placeholder="Email"
                  autoCapitalize="none"
                  autoCorrect="off"
                  type="username"
                />
              </StyledFormInput>
              <StyledFormInput
                name="first_name"
                rules={[{ required: true, message: 'This field is required' }]}
                validateStatus={error?.first_name ? 'error' : ''}
                help={error?.first_name}
              >
                <Input placeholder="First Name" />
              </StyledFormInput>
              <StyledFormInput
                name="last_name"
                rules={[{ required: true, message: 'This field is required' }]}
                validateStatus={error?.last_name ? 'error' : ''}
                help={error?.last_name}
              >
                <Input placeholder="Last Name" />
              </StyledFormInput>
              <StyledFormPassword
                name="password"
                hasFeedback
                rules={[PASSWORD_RULE]}
                validateStatus={error?.password ? 'error' : ''}
                help={error?.password}
              >
                <Input.Password
                  autoComplete="new-password"
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </StyledFormPassword>
              <StyledFormInput>
                <CreateButton
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  data-cy="register"
                >
                  Create account
                </CreateButton>
              </StyledFormInput>
            </Form>
            <TermsText>
              Already have an account?{' '}
              <Link href="/login" style={{ fontWeight: 'bold' }}>
                Sign in.
              </Link>
            </TermsText>
            <TermsText>
              By creating a Nami ML account you agree to the{' '}
              <Link href="https://www.namiml.com/legal/terms" target="_blank">
                Terms of Service
              </Link>{' '}
              and{' '}
              <Link href="https://www.namiml.com/legal/privacy" target="_blank">
                Privacy Policy.
              </Link>
            </TermsText>
          </Space>
        </Col>
        <Col xs={24} lg={12} style={{ padding: '4em', height: '100vh' }}>
          <Space
            size={64}
            direction="vertical"
            style={{
              width: '100%',
              textAlign: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
            align="center"
          >
            <Space direction="vertical" size={0}>
              <Header1>Everything you need to</Header1>
              <div>
                <Header1BG>
                  <Header1Light>power</Header1Light>
                </Header1BG>
                <Header1>your paywall.</Header1>
              </div>
            </Space>
            <Row className="intercom-valueProps-gallery" gutter={[0, 16]}>
              <Col flex={6}>
                <Space direction="vertical" size={12} style={{ width: 110 }}>
                  <Icon
                    component={JoinIcon1}
                    style={{ fontSize: 58, color: 'unset' }}
                  />
                  <IconSubtext>A universe of platforms</IconSubtext>
                </Space>
              </Col>
              <Col flex={6}>
                <Space direction="vertical" size={12} style={{ width: 110 }}>
                  <Icon component={JoinIcon2} style={{ fontSize: 58 }} />
                  <IconSubtext>No-code paywalls</IconSubtext>
                </Space>
              </Col>
              <Col flex={6}>
                <Space direction="vertical" size={12} style={{ width: 110 }}>
                  <Icon component={JoinIcon3} style={{ fontSize: 58 }} />
                  <IconSubtext>Infinite A/B Testing</IconSubtext>
                </Space>
              </Col>
              <Col flex={6}>
                <Space direction="vertical" size={12} style={{ width: 110 }}>
                  <Icon component={JoinIcon4} style={{ fontSize: 58 }} />
                  <IconSubtext>Precision segmentation</IconSubtext>
                </Space>
              </Col>
            </Row>
            <Header2>Create your first paywall in 10 minutes</Header2>
          </Space>
        </Col>
      </Row>
    </Page>
  );
};

export default JoinPage;
