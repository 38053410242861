/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useMemo, useState } from 'react';

import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Modal, Select, Space, Tag, Tooltip } from 'antd';
import {
  useBulkDuplicateRuleToPlacementsMutation,
  useCampaignLabelTagsQuery,
  useUnarchivedCampaignLabelIdQuery,
} from 'src/hooks/queries/campaign.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { namiMediumGray } from 'src/variables';

import { PlacementLabel } from './detail/CampaignLabelSelect';

type DuplicateRuleToPlacementsModalProps = {
  open: boolean;
  ruleId: string;
  onClose: () => void;
  ruleName: string;
};

export default function DuplicateRuleToPlacementsModal({
  open,
  ruleId,
  onClose,
  ruleName,
}: DuplicateRuleToPlacementsModalProps) {
  const appId = useAppSelector(({ root }) => root.currentApp?.id);
  const placements = useUnarchivedCampaignLabelIdQuery();
  const [labels, setLabels] = useState<string[]>([]);
  const [nameTemplate, setNameTemplate] = useState<string>(
    `Duplicate of ${ruleName}`
  );
  const [nameExample, setNameExample] = useState<string>('');
  const [form] = Form.useForm<{ labels: string[]; nameTemplate: string }>();
  const labelTagsQuery = useCampaignLabelTagsQuery({ pageSize: 1000 });

  const placementIdsByTag = useMemo(() => {
    return (labelTagsQuery.data?.results || []).reduce((output, labelTag) => {
      return {
        ...output,
        [labelTag.id]: labelTag.campaign_label_ids,
      };
    }, {} as Record<string, string[]>);
  }, [labelTagsQuery]);

  const selectPlacementsByTag = (tagId: string) => {
    if (placementIdsByTag[tagId]) {
      let uniqueNewLabels: string[] = [...labels];
      placementIdsByTag[tagId].forEach((placement) => {
        if (!uniqueNewLabels.includes(placement))
          uniqueNewLabels.push(placement);
      });
      setLabels(uniqueNewLabels);
    }
  };

  const options = Object.entries(placements).map(([id, placement]) => {
    return {
      key: placement.value,
      name: placement.value,
      value: id,
      label: (
        <Space direction="horizontal">
          {placement.archived && (
            <DeleteOutlined style={{ fontSize: 12, color: namiMediumGray }} />
          )}
          <PlacementLabel>{placement.value}</PlacementLabel>
        </Space>
      ),
    };
  });
  const duplicateToPlacementsMutation =
    useBulkDuplicateRuleToPlacementsMutation({
      ruleId,
      onSuccess: onClose,
    });

  const NameInputSuffix = (
    <Tooltip title="Use Smart Text variables ${placement.display_name} or ${placement.label} to craft dynamic rule names.">
      <InfoCircleOutlined />
    </Tooltip>
  );

  useEffect(() => {
    if (nameTemplate.includes('$') && labels.length > 0) {
      const firstLabelId = labels[0];
      if (placements[firstLabelId]) {
        const firstPlacement = placements[firstLabelId];
        const nameReplaced = nameTemplate
          .replace(
            '${placement.display_name}',
            firstPlacement.display_name || firstPlacement.value
          )
          .replace('${placement.label}', firstPlacement.value);
        setNameExample(`Example: ${nameReplaced}`);
      }
    } else {
      setNameExample('');
    }
  }, [labels, placements, nameTemplate]);

  return (
    <Modal
      title="Copy to Multiple Placements"
      open={open}
      onCancel={onClose}
      onOk={() => {
        duplicateToPlacementsMutation.mutate({
          app: appId,
          labels: labels,
          name_template: nameTemplate,
        });
      }}
      confirmLoading={duplicateToPlacementsMutation.isLoading}
      okText={getOKButtonText()}
      okButtonProps={{ disabled: !labels.length || !nameTemplate }}
      zIndex={1002}
      centered
      closable={false}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{ nameTemplate: nameTemplate, labels: [] }}
      >
        <Form.Item label="Placements">
          <Select
            mode="multiple"
            allowClear
            placeholder="Select placements"
            options={options}
            loading={duplicateToPlacementsMutation.isLoading}
            style={{ width: '100%' }}
            onChange={setLabels}
            value={labels}
            dropdownRender={(menu) => (
              <>
                <Space
                  size={1}
                  direction="horizontal"
                  style={{ padding: '4px 8px' }}
                >
                  {(labelTagsQuery.data?.results || []).map((tag) => (
                    <Tag
                      style={{ cursor: 'pointer' }}
                      onClick={() => selectPlacementsByTag(tag.id)}
                      key={tag.id}
                    >
                      {tag.name}
                    </Tag>
                  ))}
                </Space>
                <Divider style={{ margin: '8px 0' }} />
                {menu}
              </>
            )}
            filterOption={(
              input: string,
              option?: {
                key: string;
                name: string;
                value: string;
                label: JSX.Element;
              }
            ) =>
              (option?.name || '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item label="Name" name="nameTemplate" help={nameExample}>
          <Input
            suffix={NameInputSuffix}
            onChange={(event) => setNameTemplate(event.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );

  function getOKButtonText(): string {
    if (!labels.length) return 'Duplicate';
    return `Copy to ${labels.length} Placement${
      labels.length !== 1 ? 's' : ''
    }`;
  }
}
