import React, { useEffect, useState } from 'react';

import { DeleteOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Modal, Table, Typography } from 'antd';
import moment from 'moment';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import EmptyState from 'src/components/EmptyState/EmptyState';
import SectionTitle from 'src/components/SectionTitle';

import api from '../../../../api';
import Loading from '../../../../components/Loading/Loading';
import Responsive from '../../../../components/Responsive/Responsive';
import { useAppContext, useBooleanState } from '../../../../hooks';
import AddTokenModal from './AddTokenModal';

const ApiAccessPage = () => {
  const appContext = useAppContext();
  const canCreate = appContext.userHasEntitlement('account.token.create');
  const [apiAccessTokens, setApiAccessTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddTokenOpen, openAddToken, closeAddToken] = useBooleanState(false);
  const { confirm } = Modal;

  useEffect(() => {
    api
      .getUserAccessTokens()
      .then(setApiAccessTokens)
      .finally(() => setLoading(false));
  }, []);

  const deleteAccessToken = (token) => {
    setLoading(true);
    api
      .deleteUserAccessToken(token)
      .then(api.getUserAccessTokens)
      .then(setApiAccessTokens)
      .finally(() => setLoading(false));
  };

  const deleteModal = (token) => {
    confirm({
      title: 'Delete Access Token Confirmation',
      content: 'Are you sure you want to delete this Access Token?',
      okText: 'Yes, Delete Token.',
      okType: 'danger',
      centered: true,
      icon: null,
      cancelText: 'Cancel',
      onOk() {
        deleteAccessToken(token);
      },
    });
  };

  const columns = [
    {
      title: <Typography.Text strong>Name</Typography.Text>,
      key: 'name',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: <Typography.Text strong>Expiration</Typography.Text>,
      key: 'exp',
      dataIndex: 'exp',
      ellipsis: true,
      responsive: ['lg'],
      render: (exp) => <ExpirationLabel expirationString={exp} />,
    },
    {
      title: <Typography.Text strong>Actions</Typography.Text>,
      key: 'session',
      responsive: ['sm'],
      ellipsis: true,
      render: (accessToken) => (
        <IconActionButton
          type="text"
          size="small"
          icon={<DeleteOutlined style={{ fontSize: '13px' }} />}
          onClick={() => {
            deleteModal(accessToken.jti);
          }}
        >
          Delete
        </IconActionButton>
      ),
    },
    {
      title: <Typography.Text strong>Actions</Typography.Text>,
      key: 'id',
      width: '100px',
      responsive: ['xs'],
      render: (accessToken) => (
        <IconActionButton
          type="text"
          size="small"
          icon={<DeleteOutlined style={{ fontSize: '13px' }} />}
          // disabled={!appContext.userHasEntitlement('org.members.update')}
          onClick={() => {
            deleteModal(accessToken.jti);
          }}
        />
      ),
    },
  ];

  const dataSource = apiAccessTokens.map((accessToken) => {
    return {
      key: accessToken.session,
      ...accessToken,
    };
  });

  if (loading) return <Loading />;

  return (
    <>
      <SectionTitle>API Access</SectionTitle>
      {!apiAccessTokens.length && (
        <EmptyState
          title="You have not created any API access tokens."
          linkText="Learn more about API access tokens."
          link="https://docs.namiml.com/integrations/other-integrations/api-access"
          icon="key"
        >
          <Button
            icon={<PlusOutlined />}
            onClick={openAddToken}
            disabled={!canCreate}
          >
            Add Access Token
          </Button>
          <AddTokenModal
            isOpen={isAddTokenOpen}
            onClose={closeAddToken}
            setApiAccessTokens={setApiAccessTokens}
          />
        </EmptyState>
      )}
      {!!apiAccessTokens.length && (
        <>
          <Responsive size="mdUp">
            <Button
              style={{ float: 'right', marginBottom: 20 }}
              icon={<PlusOutlined />}
              onClick={openAddToken}
              disabled={!canCreate}
            >
              Add Access Token
            </Button>
          </Responsive>
          <Responsive size="mdDown">
            <Button
              ghost
              style={{ float: 'right', marginBottom: 20 }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={openAddToken}
              disabled={!canCreate}
            >
              Add
            </Button>
          </Responsive>
          <Table dataSource={dataSource} columns={columns} />
        </>
      )}
      <AddTokenModal
        isOpen={isAddTokenOpen}
        onClose={closeAddToken}
        setApiAccessTokens={setApiAccessTokens}
      />
    </>
  );
};

export default ApiAccessPage;

function ExpirationLabel({ expirationString }) {
  const expirationDate = moment(expirationString);
  const isExpired = moment().diff(expirationDate) > 0;
  return (
    <span>
      {expirationDate.format('YYYY/MM/DD')}
      {isExpired && <WarningFilled style={{ color: 'gold', marginLeft: 5 }} />}
    </span>
  );
}
