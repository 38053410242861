import React from 'react';

import { CopyOutlined } from '@ant-design/icons';
import { LaunchOutlined } from '@mui/icons-material';
import { Button, Col, Image, List, notification, Row, Space } from 'antd';
import { TEventLogItem } from 'src/api/types/developer.types';
import { copyTextToClipBoard } from 'src/services/helpers';
import { convertEventToLineItems } from 'src/utils/dev';
import { namiPrimaryBlue, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const ListHeader = styled.span`
  color: ${namiPrimaryBlue};
  font-size: 14px;
  font-weight: 600;
`;

const EventValueItem = styled.span`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
  overflow-wrap: anywhere;

  a:hover {
    text-decoration: underline;
  }
`;

export default function DeveloperFeedEventDetail({
  event,
}: {
  event: TEventLogItem;
}) {
  const eventData = convertEventToLineItems(event);

  return (
    <List
      header={<ListHeader>Event Data</ListHeader>}
      dataSource={eventData}
      style={{
        backgroundColor: namiPureWhite,
        padding: '12px 24px',
      }}
      renderItem={(item) => (
        <List.Item>
          <Row gutter={[8, 8]} style={{ width: '100%' }}>
            <Col md={8}>
              <b>{item.name}:</b>
            </Col>
            <Col md={16}>
              <Space wrap={true} style={{ width: '100%' }}>
                {item.image && (
                  <Image
                    height={15}
                    width={15}
                    preview={false}
                    src={item.image}
                  />
                )}
                <EventValueItem>
                  {item.link ? (
                    <a href={item.link}>
                      {item.value}{' '}
                      <LaunchOutlined
                        style={{ fontSize: 13, transform: 'translateY(1px)' }}
                      />
                    </a>
                  ) : (
                    item.value
                  )}
                </EventValueItem>
                {item.copyable && (
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() =>
                      copyTextToClipBoard(item.copyValue || item.value).then(
                        () =>
                          notification.success({
                            message: `Copied ${item.name} to clipboard`,
                          })
                      )
                    }
                    type="text"
                    size="small"
                  />
                )}
              </Space>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
}
