import React from 'react';

import { Col, Row, Space } from 'antd';
import Responsive from 'src/components/Responsive/Responsive';

import Page from '../../../components/Page/Page';
import HeroColumn from './HeroColumn';

type ConfirmEmailSkeletonProps = {
  title: string;
  children: React.ReactNode;
};

export default function ConfirmEmailSkeleton({
  title,
  children,
}: ConfirmEmailSkeletonProps) {
  return (
    <Page title={title} contentContainerClass="full-main-container-white">
      <Row style={{ height: '100vh' }} justify="center" align="middle">
        <Responsive size="mdUp">
          <HeroColumn xs={24} lg={12} />
          <Col xs={24} lg={12} style={{ padding: '4em 8%', height: '100vh' }}>
            <Space
              size={30}
              direction="vertical"
              style={{
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
              align="center"
            >
              {children}
            </Space>
          </Col>
        </Responsive>
        <Responsive size="mdDown">
          <Col xs={24} lg={12} style={{ padding: '4em 8%', height: '100vh' }}>
            <Space
              size={30}
              direction="vertical"
              style={{
                width: '100%',
                textAlign: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
              align="center"
            >
              {children}
            </Space>
          </Col>
          <HeroColumn xs={24} lg={12} />
        </Responsive>
      </Row>
    </Page>
  );
}
