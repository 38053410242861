import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { useCampaignLabelTagsQuery } from 'src/hooks/queries/campaign.hooks';
import styled from 'styled-components';

import StatusSelect from './StatusSelect';

type TagFilterProps = {
  selectedTag: string | null;
  onChange: (value: string | null) => void;
  onClear: () => void;
};

const WithWidth = styled.div<{ as: any }>`
  width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

export default function TagFilter({
  selectedTag,
  onChange,
  onClear,
}: TagFilterProps) {
  const labelTagsQuery = useCampaignLabelTagsQuery({ pageSize: 100 });
  const options = (labelTagsQuery.data?.results || []).map((tag) => ({
    key: tag.id,
    name: tag.name,
    value: tag.name,
    label: tag.name,
  }));

  return (
    <SelectWrapper>
      <WithWidth
        as={StatusSelect}
        allowClear
        mode={undefined}
        showArrow={false}
        optionFilterProp="text"
        options={options}
        value={selectedTag}
        onChange={(value: string) => onChange(value)}
        onClear={onClear}
        clearIcon={<CloseOutlined />}
        label={selectedTag ? 'Tag:' : ''}
        placeholder={
          <>
            Filter by <b>Tag</b>
          </>
        }
      />
    </SelectWrapper>
  );
}
