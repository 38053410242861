import { TDevice, TProductGroup } from 'src/api/types/paywall.types';
import {
  TButtonContainer,
  TCollapseContainer,
  TComponent,
  TComponentLocation,
  TContainer,
  TIdTypeLocation,
  TPaywallIdMeta,
  TPaywallPage,
  TPaywallTemplate,
  TPlayPauseButtonComponent,
  TTemplateWarning,
  TTextComponent,
  TVolumeControlComponent,
} from 'src/api/types/paywallTemplate.types';
import { TPlatformProducts } from 'src/redux/PaywallBuilderSlice';

import { LegalCitationType } from '../api/types/legalCitation.types';
import { getAttr } from '../pages/admin/paywalls/utils/functions';
import {
  findComponentFromLocation,
  getProductGroupIndexFromId,
} from './paywall';

type LegalReplacementType = {
  clickwrap_text: string;
  terms_link: string;
  privacy_link: string;
};

type ReplacementsType<T = any> = {
  [type: string]: T extends Array<any> ? never : T;
};

const VAR_REGEX = /\$\{\s*(\w+(\.[a-zA-Z\d_:${}-]{0,99})*?)\s*}/g;

export function interpolate<T extends any>(
  value: T,
  replacements: ReplacementsType
): T {
  if (Array.isArray(value)) {
    return value.reduce((output, item) => {
      const newValue = interpolate(item, replacements);
      return Array.isArray(newValue)
        ? [...output, ...newValue]
        : [...output, newValue];
    }, []) as T;
  }
  if (value === null || value === undefined) return value;
  if (typeof value === 'object') {
    return Object.entries(value as { [key: string]: any }).reduce(
      (output, [key, value]) => {
        const parsedValue =
          key === 'newRow' ? value : interpolate(value, replacements);
        return { ...output, [key]: parsedValue };
      },
      {}
    ) as T;
  }
  if (typeof value === 'string') return interpolateString(value, replacements);
  return value;
}

function walkComponentAndConstructHierarchy(
  page: number,
  value: TComponent,
  idRoot: string,
  resultList: { [key: string]: string }
): { [key: string]: string } {
  if (value.namiComponentType === 'collapse') {
    resultList[
      (value as TCollapseContainer).collapseHeader.id || ''
    ] = `${idRoot}-collapseHeader`;

    const headerResults = walkComponentAndConstructHierarchy(
      page,
      (value as TCollapseContainer).collapseHeader,
      `${idRoot}-collapseHeader`,
      resultList
    );

    resultList = {
      ...resultList,
      ...headerResults,
    };
  }
  if (((value as TContainer).components || []).length > 0) {
    return (value as TContainer).components.reduce(
      (output, component, index) => {
        resultList[value.id!] = `${idRoot}`;
        return {
          ...output,
          ...walkComponentAndConstructHierarchy(
            page,
            component,
            idRoot + `-${index}`,
            resultList
          ),
        };
      },
      resultList
    );
  }
  resultList[value.id!] = idRoot;
  return resultList;
}

export function walkComponentAndConstructIdMetadata(
  page: number,
  value: TComponent,
  idRoot: string,
  results: TPaywallIdMeta
): TPaywallIdMeta {
  if (value.namiComponentType === 'collapse') {
    results.idLocations[
      (value as TCollapseContainer).collapseHeader.id || ''
    ] = `${idRoot}-collapseHeader`;

    const headerResults = walkComponentAndConstructIdMetadata(
      page,
      (value as TCollapseContainer).collapseHeader,
      `${idRoot}-collapseHeader`,
      results
    );

    results = {
      ...results,
      ...headerResults,
    };
  }

  if (value.namiComponentType === 'volumeButton') {
    results.idLocations['volumeComponents'] = idRoot + '-volumeComponents';
    results.idLocations['mutedComponents'] = idRoot + '-mutedComponents';
    const volumeComponents = (
      value as TVolumeControlComponent
    ).volumeComponents.reduce((output, component, index) => {
      results.idLocations[value.id!] = idRoot;
      return {
        ...output,
        ...walkComponentAndConstructIdMetadata(
          page,
          component,
          idRoot + `-volumeComponents-${index}`,
          results
        ),
      };
    }, results);

    const mutedComponents = (
      value as TVolumeControlComponent
    ).mutedComponents.reduce((output, component, index) => {
      results.idLocations[value.id!] = idRoot;
      return {
        ...output,
        ...walkComponentAndConstructIdMetadata(
          page,
          component,
          idRoot + `-mutedComponents-${index}`,
          results
        ),
      };
    }, results);

    results = {
      ...results,
      ...volumeComponents,
      ...mutedComponents,
    };
  }

  if (value.namiComponentType === 'playPauseButton') {
    results.idLocations['playingComponents'] = idRoot + '-playingComponents';
    results.idLocations['pausedComponents'] = idRoot + '-pausedComponents';
    const playingComponents = (
      value as TPlayPauseButtonComponent
    ).playingComponents.reduce((output, component, index) => {
      results.idLocations[value.id!] = idRoot;
      return {
        ...output,
        ...walkComponentAndConstructIdMetadata(
          page,
          component,
          idRoot + `-playingComponents-${index}`,
          results
        ),
      };
    }, results);

    const pausedComponents = (
      value as TPlayPauseButtonComponent
    ).pausedComponents.reduce((output, component, index) => {
      results.idLocations[value.id!] = idRoot;
      return {
        ...output,
        ...walkComponentAndConstructIdMetadata(
          page,
          component,
          idRoot + `-pausedComponents-${index}`,
          results
        ),
      };
    }, results);

    results = {
      ...results,
      ...playingComponents,
      ...pausedComponents,
    };
  }

  if (((value as TContainer).components || []).length > 0) {
    return (value as TContainer).components.reduce(
      (output, component, index) => {
        results.idLocations[value.id!] = idRoot;
        if (value.namiComponentType) {
          results.idLocationsByType[value.namiComponentType] = [
            ...(results.idLocationsByType[value.namiComponentType] || []),
            `${idRoot}`,
          ];
        }
        return {
          ...output,
          ...walkComponentAndConstructIdMetadata(
            page,
            component,
            idRoot + `-${index}`,
            results
          ),
        };
      },
      results
    );
  }
  results.idLocations[value.id!] = idRoot;
  if (value.namiComponentType) {
    results.idLocationsByType[value.namiComponentType] = [
      ...(results.idLocationsByType[value.namiComponentType] || []),
      idRoot,
    ];
  }
  return results;
}

function walkComponentAndReturnWarnings(
  value: TComponent,
  result: Array<TTemplateWarning>,
  productGroups: TProductGroup[]
): Array<TTemplateWarning> {
  //Check for empty text values and text with trailing whitespace
  if (value.component === 'text' && !value.hidden) {
    if (!(value as TTextComponent).text) {
      result.push({
        message: `${value.title || value.id} has empty Content`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }

    if (
      (value as TTextComponent).text.trimEnd() !==
      (value as TTextComponent).text
    ) {
      result.push({
        message: `${value.title || value.id} has whitespace at the end`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  //Check for group without both alignment values
  if (value.component === 'container' && !value.hidden) {
    if (value.verticalAlignment && !value.horizontalAlignment) {
      result.push({
        message: `${
          value.title || value.id
        } needs the Horizontal Alignment property set`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }

    if (!value.verticalAlignment && value.horizontalAlignment) {
      result.push({
        message: `${
          value.title || value.id
        } needs the Vertical Alignment property set`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  //Check for empty button
  if (value.component === 'button' && !value.hidden) {
    if (
      !(value as TButtonContainer).components ||
      (value as TButtonContainer).components.length === 0
    ) {
      result.push({
        message: `${value.title || value.id} is missing content`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  //Check for empty deeplink Url
  if (value.namiComponentType === 'deeplinkButton' && !value.hidden) {
    if (!(value as any).url) {
      result.push({
        message: `${value.title || value.id} is missing a destination URL`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  //Check for empty video URL
  if (value.namiComponentType === 'videoUrl' && !value.hidden) {
    if (!(value as any).url) {
      result.push({
        message: `${value.title || value.id} is missing a URL value`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }

    if ((value as any).url.trimEnd() !== (value as any).url) {
      result.push({
        message: `${value.title || value.id} has an invalid URL value`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  //Check for empty repeating grid Datasource
  if (value.namiComponentType === 'repeatingList') {
    if (!(value as any).loopSource) {
      result.push({
        message: `${value.title || value.id} is missing a Data Source`,
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  //Check for invalid Product Container Datasource
  if (value.component === 'productContainer') {
    if ((value as any).subsetGroup) {
      const productGroupIndex = getProductGroupIndexFromId(
        (value as any).subsetGroup
      );
      if (productGroupIndex >= productGroups.length) {
        result.push({
          message: `${value.title || value.id} has an invalid Data Source`,
          editingComponentId: value.id || null,
          menuItem: null,
        });
      }
    }
  }

  //Check for top & bottom padding if item has fixed height
  // if ((value as any).height && !value.hidden) {
  //   if (
  //     (value as any).height !== 'fitContent' &&
  //     (value as any).height !== '100%'
  //   ) {
  //     if (
  //       value.namiComponentType !== 'productGroupToggle' &&
  //       ((value as any).topPadding || (value as any).bottomPadding)
  //     ) {
  //       result.push({
  //         message: `${
  //           value.title || value.id
  //         } shouldn't have Top or Bottom Padding since the Height is fixed.`,
  //         editingComponentId: value.id || null,
  //         menuItem: null,
  //       });
  //     }
  //   }
  // }

  // //Check for left & right padding if item has fixed width
  // if ((value as any).width) {
  //   if (
  //     (value as any).width !== 'fitContent' &&
  //     (value as any).width !== '100%'
  //   ) {
  //     if (
  //       value.namiComponentType !== 'productGroupToggle' &&
  //       ((value as any).leftPadding || (value as any).rightPadding)
  //     ) {
  //       result.push({
  //         message: `${
  //           value.title || value.id
  //         } shouldn't have Left or Right Padding since the Width is fixed.`,
  //         editingComponentId: value.id || null,
  //         menuItem: null,
  //       });
  //     }
  //   }
  // }

  //Check for missing conditional styles
  if (value.conditionAttributes) {
    for (const attributeIndex in value.conditionAttributes) {
      if (
        Object.keys(value.conditionAttributes[attributeIndex].attributes)
          .length < 1
      ) {
        result.push({
          message: `${
            value.title || value.id
          } condition block is missing styles`,
          editingComponentId: value.id || null,
          menuItem: null,
          conditionsTab: true,
        });
      }
    }
  }

  //Check for empty collapse body
  if (value.namiComponentType === 'collapseBody' && !value.hidden) {
    if (!((value as TContainer).components || []).length) {
      result.push({
        message: 'Collapse Body missing content',
        editingComponentId: value.id || null,
        menuItem: null,
      });
    }
  }

  if (((value as TContainer).components || []).length > 0) {
    // if (value.component === 'condition') {
    //   if (((value as TConditionalComponent).components || []).length > 1) {
    //     result.push({
    //       message: `Conditions can only support 1 child component`,
    //       editingComponentId: value.id || null,
    //       menuItem: null,
    //     });
    //   }
    //   const conditionalBlocks = (value.assertions || []).map((assertion) => {
    //     return `${assertion.value} ${assertion.operator} ${assertion.expected}`;
    //   });

    //   if (new Set(conditionalBlocks).size !== conditionalBlocks.length) {
    //     result.push({
    //       message: `Condition uses same rule block twice`,
    //       editingComponentId: value.id || null,
    //       menuItem: null,
    //       conditionsTab: true,
    //     });
    //   }
    // }
    return (value as TContainer).components.reduce((output, component) => {
      return [
        ...output,
        ...walkComponentAndReturnWarnings(component, result, productGroups),
      ];
    }, result);
  }

  return result;
}

export function grabVariableValuesFromComponentAndChildren(
  component: TComponent
): string[] {
  return Object.entries(component).reduce((output, [key, value]) => {
    if (key === 'components') {
      return ((component as TContainer).components || []).reduce(
        (input, child) => {
          return [
            ...input,
            ...grabVariableValuesFromComponentAndChildren(child),
          ];
        },
        output
      );
    }
    return [...output, value];
  }, [] as string[]);
}

export function pullPageHeader(value: TPaywallPage): TComponent[] {
  if (value.header.length) {
    if ((value.header[0] as TContainer).components)
      return (value.header[0] as TContainer).components;
    return value.header;
  }
  return [];
}

export function pullPageFooter(value: TPaywallPage): TComponent[] {
  if (value.footer && value.footer.length > 0) {
    if ((value.footer[0] as TContainer).components) {
      return (value.footer[0] as TContainer).components;
    }
    return value.footer;
  }
  return [];
}

export function findCollapseComponentParent(
  value: TComponentLocation,
  pages: TPaywallPage[]
): TCollapseContainer | undefined {
  if (!value.pos.includes('collapseHeader')) return undefined;
  if (!pages.length) return undefined;
  const collapseHeaderLocationInPos = value.pos.findIndex(
    (value) => value === 'collapseHeader'
  );
  const componentParentLocation = {
    page: value.page,
    section: value.section,
    pos: value.pos.slice(0, collapseHeaderLocationInPos),
  };
  return findComponentFromLocation(
    pages,
    componentParentLocation
  ) as TCollapseContainer;
}

export function constructIdLocations(value: TPaywallPage[]): {
  [key: string]: string;
} {
  return value.reduce((output, page, index) => {
    let requiredSectionOutput = {};

    if (page.header && page.header.length) {
      requiredSectionOutput = {
        ...requiredSectionOutput,
        ...walkComponentAndConstructHierarchy(
          index,
          {
            id: 'header',
            component: 'container',
            components: pullPageHeader(page),
          },
          `${index}-header`,
          {}
        ),
      };
    }

    requiredSectionOutput = {
      ...requiredSectionOutput,
      ...walkComponentAndConstructHierarchy(
        index,
        page.contentContainer,
        `${index}-contentContainer`,
        {}
      ),
      ...walkComponentAndConstructHierarchy(
        index,
        page.backgroundContainer,
        `${index}-backgroundContainer`,
        {}
      ),
    };

    if (page.footer && page.footer.length) {
      requiredSectionOutput = {
        ...requiredSectionOutput,
        ...walkComponentAndConstructHierarchy(
          index,
          {
            id: 'footer',
            component: 'container',
            components: pullPageFooter(page),
          },
          `${index}-footer`,
          {}
        ),
      };
    }
    return {
      ...output,
      ...requiredSectionOutput,
    };
  }, {} as { [key: string]: string });
}

export function constructIdMetadata(value: TPaywallPage[]): TPaywallIdMeta {
  return value.reduce(
    (output, page, index) => {
      let requiredSectionOutput: TPaywallIdMeta = {
        idLocations: {},
        idLocationsByType: {},
      };

      if (page.header && page.header.length) {
        const headerMeta = walkComponentAndConstructIdMetadata(
          index,
          {
            id: `header${index === 0 ? '' : index - 1}`,
            component: 'container',
            components: pullPageHeader(page),
          },
          `${index}-header`,
          {
            idLocations: {},
            idLocationsByType: {},
          } as TPaywallIdMeta
        );
        requiredSectionOutput.idLocations = {
          ...requiredSectionOutput.idLocations,
          ...headerMeta.idLocations,
        };
        requiredSectionOutput.idLocationsByType = {
          ...requiredSectionOutput.idLocationsByType,
          ...headerMeta.idLocationsByType,
        };
      }

      const contentMeta = walkComponentAndConstructIdMetadata(
        index,
        page.contentContainer,
        `${index}-contentContainer`,
        {
          idLocations: {},
          idLocationsByType: {},
        } as TPaywallIdMeta
      );
      requiredSectionOutput.idLocationsByType = mergeKeyArrayObjects(
        requiredSectionOutput.idLocationsByType,
        contentMeta.idLocationsByType
      );

      const bgMeta = walkComponentAndConstructIdMetadata(
        index,
        page.backgroundContainer,
        `${index}-backgroundContainer`,
        {
          idLocations: {},
          idLocationsByType: {},
        } as TPaywallIdMeta
      );
      requiredSectionOutput.idLocationsByType = mergeKeyArrayObjects(
        requiredSectionOutput.idLocationsByType,
        bgMeta.idLocationsByType
      );

      requiredSectionOutput.idLocations = {
        ...requiredSectionOutput.idLocations,
        ...contentMeta.idLocations,
        ...bgMeta.idLocations,
      };

      if (page.footer && page.footer.length) {
        const footerMeta = walkComponentAndConstructIdMetadata(
          index,
          {
            id: 'footer',
            component: 'container',
            components: pullPageFooter(page),
          },
          `${index}-footer`,
          {
            idLocations: {},
            idLocationsByType: {},
          } as TPaywallIdMeta
        );

        requiredSectionOutput.idLocations = {
          ...requiredSectionOutput.idLocations,
          ...footerMeta.idLocations,
        };
        requiredSectionOutput.idLocationsByType = mergeKeyArrayObjects(
          requiredSectionOutput.idLocationsByType,
          footerMeta.idLocationsByType
        );
      }
      return {
        idLocations: {
          ...output.idLocations,
          ...requiredSectionOutput.idLocations,
        },
        idLocationsByType: mergeKeyArrayObjects(
          output.idLocationsByType,
          requiredSectionOutput.idLocationsByType
        ),
      };
    },
    {
      idLocations: {},
      idLocationsByType: {},
    } as TPaywallIdMeta
  );
}

function mergeKeyArrayObjects(
  original: TIdTypeLocation,
  add: TIdTypeLocation
): TIdTypeLocation {
  return Object.keys(add).reduce((output, type) => {
    if (output[type]) {
      return {
        ...output,
        [type]: [...output[type], ...add[type]],
      };
    }
    return {
      ...output,
      [type]: add[type],
    };
  }, original);
}

export function createPaywallWarnings(
  template: TPaywallTemplate,
  products: TPlatformProducts,
  productGroups: TProductGroup[],
  formFactor: TDevice
): Set<TTemplateWarning> {
  let warnings: Array<TTemplateWarning> = [];

  let totalProductCount = 0;
  let featuredProductCount = 0;
  let totalProductCountByGroup = Object.keys(products).reduce(
    (output, group) => {
      return {
        ...output,
        [group]: 0,
      };
    },
    {} as { [key: string]: number }
  );

  for (const group in products) {
    for (const platform in products[group]) {
      for (const productId in products[group][platform]) {
        totalProductCount++;
        totalProductCountByGroup[group]++;
        if (products[group][platform][productId].featured) {
          featuredProductCount++;
        }
      }
    }
  }

  if (totalProductCount === 0) {
    warnings.push({
      menuItem: 'products',
      message: 'No products added to the paywall',
      editingComponentId: null,
    });
  } else {
    for (const groupIndex in productGroups) {
      const group = productGroups[groupIndex];
      if (
        template['ui.capabilities']?.includes('product_groups') &&
        !totalProductCountByGroup[group.id]
      ) {
        warnings.push({
          menuItem: 'products',
          message: `No products added to the ${group.display_name} group`,
          editingComponentId: null,
          groupId: group.id,
        });
      }
    }
  }

  //Check that at least 1 product is marked as featured
  if (
    template['ui.capabilities']?.includes('featured_product') &&
    !featuredProductCount
  ) {
    warnings.push({
      menuItem: 'products',
      message: 'No products marked as Featured.',
      editingComponentId: null,
    });
  } else if (
    template['ui.hasFeaturedFlag'] &&
    formFactor === 'television' &&
    !featuredProductCount
  ) {
    warnings.push({
      menuItem: 'products',
      message: 'No products marked as Focused.',
      editingComponentId: null,
    });
  }

  //Loop through template
  const outputArray = template.pages.reduce((output, page) => {
    return [
      ...output,
      ...walkComponentAndReturnWarnings(
        {
          id: 'header',
          component: 'container',
          components: pullPageHeader(page),
        },
        [] as TTemplateWarning[],
        productGroups
      ),
      ...walkComponentAndReturnWarnings(
        page.contentContainer,
        [] as TTemplateWarning[],
        productGroups
      ),
      ...walkComponentAndReturnWarnings(
        page.backgroundContainer,
        [] as TTemplateWarning[],
        productGroups
      ),
      ...walkComponentAndReturnWarnings(
        {
          id: 'header',
          component: 'container',
          components: pullPageHeader(page),
        },
        [] as TTemplateWarning[],
        productGroups
      ),
      ...walkComponentAndReturnWarnings(
        {
          id: 'footer',
          component: 'container',
          components: pullPageFooter(page),
        },
        [] as TTemplateWarning[],
        productGroups
      ),
    ];
  }, warnings);
  return new Set(outputArray);
}

export function interpolateString(
  value: string,
  replacements: ReplacementsType
): any {
  let output = value;
  for (const [match, rawPath] of Array.from(value.matchAll(VAR_REGEX))) {
    const path = interpolateString(rawPath, replacements);
    // If the variable is the whole string, return the raw value (e.g. number)
    if (match === value) return replacer(match, path);
    // Otherwise, replace it within the string
    if (path !== rawPath) {
      output = output.replace(rawPath, path);
    }
    output = output.replace(VAR_REGEX, replacer);
  }
  return output;

  function replacer(match: string, path: string): string {
    const newValue = getAttr(replacements, ...path.split('.'));
    if (newValue === undefined) return match;
    if (typeof newValue !== 'string') return newValue;
    return interpolateString(newValue, replacements);
  }
}

export function buildLegalVariables(
  citation: LegalCitationType
): LegalReplacementType {
  const { clickwrap_text } = citation;
  const terms_link = `[${citation.tos_text}](${citation.tos_url})`;
  const privacy_link = `[${citation.privacy_text}](${citation.privacy_url})`;
  return { clickwrap_text, terms_link, privacy_link };
}
