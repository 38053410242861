import React from 'react';

import { namiBrightGreen, namiGreen } from 'src/variables';
import styled from 'styled-components';

const Dot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1.5px solid ${namiBrightGreen};
  background-color: ${namiGreen};

  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1.5s blink ease infinite;

  @keyframes blink {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }

  @-moz-keyframes blink {
    from,
    to {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }

  @-webkit-keyframes "blink" {
    from,
    to {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }

  @-ms-keyframes "blink" {
    from,
    to {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }

  @-o-keyframes "blink" {
    from,
    to {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
`;

export default function LiveDot() {
  return <Dot />;
}
