import React from 'react';

import { usePaywallsQuery } from 'src/hooks/queries/paywall.hooks';

import SummaryCard from './SummaryCard';

export default function PaywallSummaryCard() {
  const paywalls = usePaywallsQuery({
    pageSize: 20,
    enabled: true,
    archived: false,
  });

  return (
    <SummaryCard
      title="Live Paywalls"
      loading={paywalls.isLoading}
      value={paywalls.data?.count}
      link={'/paywalls?status=enabled'}
    />
  );
}
