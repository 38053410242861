import React from 'react';

import {
  AccountCircleOutlined,
  AutorenewOutlined,
  CancelOutlined,
  CreditCardOffOutlined,
  ErrorOutline,
  PaidOutlined,
  PauseCircleOutline,
  PlayCircleOutline,
  SyncDisabledOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';

export type TEventLogEventType =
  | 'device.impression'
  | 'device.session.start'
  | 'device.session.end'
  | 'device.transaction'
  // | 'purchase.updated'
  | 'user.subscription.purchased'
  | 'user.subscription.cancelled'
  | 'user.subscription.renewed'
  | 'user.subscription.expired'
  | 'user.subscription.in_grace_period'
  | 'user.subscription.renewal_in_grace_period'
  | 'user.subscription.paused'
  | 'user.subscription.resumed'
  | 'user.subscription.account_hold';

export const prettyEventTypeNames: Record<TEventLogEventType, string> = {
  'device.impression': 'Impression',
  'device.session.start': 'Session Start',
  'device.session.end': 'Session End',
  'device.transaction': 'Transaction',
  'user.subscription.purchased': 'Purchase',
  'user.subscription.renewed': 'Renewal',
  'user.subscription.cancelled': 'Cancellation',
  'user.subscription.expired': 'Expiration',
  'user.subscription.in_grace_period': 'Enter Grace Period',
  'user.subscription.renewal_in_grace_period': 'Renewal in Grace Period',
  'user.subscription.account_hold': 'Account Hold',
  'user.subscription.paused': 'Pause',
  'user.subscription.resumed': 'Resume',
};

export const eventTypeGroups: Record<string, TEventLogEventType[]> = {
  Device: [
    'device.impression',
    'device.session.start',
    'device.session.end',
    'device.transaction',
  ],
  Subscription: [
    'user.subscription.purchased',
    'user.subscription.cancelled',
    'user.subscription.renewed',
    'user.subscription.expired',
    'user.subscription.paused',
    'user.subscription.resumed',
    'user.subscription.in_grace_period',
    'user.subscription.renewal_in_grace_period',
    'user.subscription.account_hold',
  ],
};

export const eventTypeIcons: Record<TEventLogEventType, JSX.Element> = {
  'device.impression': <VisibilityOutlined />,
  'device.session.start': <AccountCircleOutlined />,
  'device.session.end': <AccountCircleOutlined />,
  'device.transaction': <PaidOutlined />,
  'user.subscription.purchased': <PaidOutlined />,
  'user.subscription.cancelled': <SyncDisabledOutlined />,
  'user.subscription.renewed': <AutorenewOutlined />,
  'user.subscription.expired': <CancelOutlined />,
  'user.subscription.paused': <PauseCircleOutline />,
  'user.subscription.resumed': <PlayCircleOutline />,
  'user.subscription.in_grace_period': <CreditCardOffOutlined />,
  'user.subscription.account_hold': <ErrorOutline />,
  'user.subscription.renewal_in_grace_period': <AutorenewOutlined />,
};

export type TEventLogItem = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  device: TEventLogDeviceItem | null;
  event_type: TEventLogEventType;
  event: TEventLogEventItem;
};

export type TEventLogDeviceItem = {
  id: string;
  created_date?: string;
  created_date_humanized?: string;
  external_id?: string | null;
  nami_user_id?: string;
  nami_user_type?: string; //TODO
  platform: TEventLogPlatformItem;
};

export type TEventLogPlatformItem = {
  id: string;
  name: string;
  marketplace_app_id: string;
  platform_type: string; //TODO
  app?: {};
};

export type TEventLogEventItem = {
  data?: TEventLogEventDataItem;
  event_platform?: string; //TODO - types
  event_type: TEventLogEventType;
  impression_id?: string;
  session_id?: string;
  active_entitlements?: TEventLogEventEntitlements[];
  devices?: TEventLogDeviceItem[];
  user_id?: string;
  external_ids?: []; //TODO
};

export type TEventLogEventDataItem = {
  id: string;
  app?: string;
  device?: string;
  devices?: [];
  app_env?: 'beta_testing'; //TODO
  start_time?: string | null;
  end_time?: string | null;
  active_entitlements?: TEventLogEventEntitlements[];
  customer_journey_state?: {}; //TODO
  external_id?: string | null;
  external_ids?: [];
  platform: string;
  segment?: string | null;
  session?: string | null;
  call_to_action?: string | null;
  call_to_action_name?: string | null;
  controller_name?: string | null;
  amount?: string | null;
  currency?: string | null;
  entitlement?: string | null;
  sku?: string | null;
  sku_ref_id?: string | null;
  sku_name?: string | null;
  sku_type?: string | null;
  original_transaction_id?: string | null;
  transaction_id?: string | null;
  source?: string | null;
};

export type TEventLogEventEntitlements = {
  description: string;
  entitlement_ref_id: string;
  expiration: string;
  name: string;
  purchase_platform: string;
  sku_ref_id: string;
  sku_name: string;
};

export const startDateLookbackInHours = 24;
