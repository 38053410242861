import React, { useEffect, useMemo, useState } from 'react';

import { LockOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import SearchInput from 'src/components/SearchInput';
import { InfinitePlacementsWebPaywall } from 'src/components/WebPaywalls/InfinitePlacementsWebPaywall';
import { MultiplePlacementsWebPaywall } from 'src/components/WebPaywalls/MultiplePlacementsWebPaywall';
import { useAppContext, useBooleanState, useQueryParams } from 'src/hooks';
import { useApiOptions } from 'src/hooks/queries/apiOptions.hooks';
import { useCampaignLabelsQuery } from 'src/hooks/queries/campaign.hooks';
import { namiLightGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import AddLabelModal from './AddLabelModal';
import { getEnabledStatus } from './CampaignsWrapper';
import ExportPlacementsButton from './ExportPlacementsButton';
import PlacementsTable from './PlacementsTable';
import StatusFilter from './StatusFilter';
import TagFilter from './TagFilter';

export const ToolbarRow = styled(Row)`
  background: ${namiPureWhite};
  border-bottom: 1px solid ${namiLightGray};
  padding: 12px;
`;

export default function PlacementsWrapper() {
  const { userHasEntitlement, planHasEntitlement } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const [status, setStatus] = useState<string | null>(
    queryParams.get('status') === '' ? null : queryParams.get('status')
  );
  const [tag, setTag] = useState<string | null>(
    queryParams.get('tag') === '' ? null : queryParams.get('tag')
  );

  const [apiOptions, updateApiOptions] = useApiOptions({
    pageSize: 20,
    enabled: getEnabledStatus(status),
    archived: getArchivedStatus(),
    tag: tag || '',
  });
  const labelsQuery = useCampaignLabelsQuery({
    ...apiOptions,
  });
  const [isLabelModalOpen, openLabelModal, closeLabelModal] =
    useBooleanState(false);
  const [isMultipleUpgradeOpen, openMultipleUpgrade, closeMultipleUpgrade] =
    useBooleanState(false);
  const [isInfiniteUpgradeOpen, openInfiniteUpgrade, closeInfiniteUpgrade] =
    useBooleanState(false);

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      enabled: getEnabledStatus(status),
      archived: getArchivedStatus(),
      tag: tag || '',
    });
    queryParams.set('status', status || '');
    queryParams.set('tag', tag || '');
    location.search = queryParams.toString();
    history.push(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, tag]);

  const placementTagsUnlocked = planHasEntitlement(
    'app.placement.create_infinite'
  );

  const canCreateMorePlacements: boolean = useMemo(() => {
    if (planHasEntitlement('app.placement.create_infinite')) return true;
    const currentCount = labelsQuery.data?.count || 0;
    if (planHasEntitlement('app.placement.create_three') && currentCount < 3)
      return true;
    return currentCount < 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsQuery.data]);

  return (
    <>
      <ToolbarRow gutter={[8, 8]}>
        <Col xs={24} md={6}>
          <SearchInput
            placeholder="Search placement"
            value={apiOptions.search || ''}
            onChange={(search) => {
              updateApiOptions({ search });
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <StatusFilter
            status={status}
            onChange={setStatus}
            onClear={() => setStatus(null)}
            includeArchived={true}
          />
        </Col>
        {placementTagsUnlocked && (
          <Col xs={12} md={6}>
            <TagFilter
              selectedTag={tag}
              onChange={setTag}
              onClear={() => setTag(null)}
            />
          </Col>
        )}
        <Col xs={24} md={placementTagsUnlocked ? 6 : 12}>
          <Space direction="horizontal" size="small" style={{ float: 'right' }}>
            {getExportButton()}
            {getAddButton()}
          </Space>
        </Col>
      </ToolbarRow>
      <PlacementsTable
        apiOptions={apiOptions}
        onApiOptionsChange={updateApiOptions}
      />
      <AddLabelModal isOpen={isLabelModalOpen} onClose={closeLabelModal} />
      <MultiplePlacementsWebPaywall
        visible={isMultipleUpgradeOpen}
        onCancel={closeMultipleUpgrade}
      />
      <InfinitePlacementsWebPaywall
        visible={isInfiniteUpgradeOpen}
        onCancel={closeInfiniteUpgrade}
      />
    </>
  );

  function getExportButton() {
    if (planHasEntitlement('app.placement.create_infinite')) {
      if (planHasEntitlement('app.placement.bulk_update')) {
        return <ExportPlacementsButton />;
      } else {
        return (
          <Button
            icon={<LockOutlined />}
            style={{ float: 'right', cursor: 'pointer' }}
            className="intercom-gatedExportPlacements"
            onClick={openInfiniteUpgrade}
            type="text"
          >
            Export
          </Button>
        );
      }
    }
    return undefined;
  }

  function getAddButton() {
    if (canCreateMorePlacements) {
      return (
        <Button
          type="primary"
          onClick={openLabelModal}
          icon={<PlusOutlined />}
          disabled={!userHasEntitlement('app.campaign.create')}
          className="intercom-addPlacement"
        >
          Add
        </Button>
      );
    } else if (planHasEntitlement('app.placement.create_three')) {
      return (
        <Button
          onClick={openInfiniteUpgrade}
          icon={<LockOutlined />}
          className="intercom-addPlacement"
        >
          Add
        </Button>
      );
    } else {
      return (
        <Button
          onClick={openMultipleUpgrade}
          icon={<LockOutlined />}
          className="intercom-addPlacement"
        >
          Add
        </Button>
      );
    }
  }

  function getArchivedStatus(): boolean {
    if (status === 'archived') return true;
    return false;
  }
}
