import React from 'react';

import { TContainer } from 'src/api/types/paywallTemplate.types';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import {
  applyStyles,
  flexDirection,
  pickAndApplyBackgroundColor,
} from '../css';
import { applyChildSizing, parsePosition } from './Container';

type TeasedSlideProps = {
  component: TContainer;
  prevSlide: boolean;
  padding: number;
  scale: number;
  inFocusedState?: boolean;
  minSDKVersion: TSemverObj;
};

const SlideWrapper = styled.div<{
  component: TContainer;
  inFocusedState?: boolean;
  minSDKVersion: TSemverObj;
  prevSlide: boolean;
  scale: number;
  padding: number;
}>`
  display: flex;
  ${({
    component,
    inFocusedState,
    minSDKVersion,
    prevSlide,
    scale,
    padding,
  }) => {
    const isVertical = component.direction === 'vertical';
    const sizeKey = isVertical ? 'width' : 'height';
    const spaceKey = isVertical ? 'margin-top' : 'margin-left';
    const height =
      typeof component.height === 'number'
        ? `${component.height * scale}px`
        : '300px'; //TODO - better default
    const background = !!component.fillImage
      ? css`
          background-image: url(${component.fillImage});
          background-size: cover;
          background-position: ${prevSlide ? 'right' : 'left'};
          background-repeat: no-repeat;
          height: 100%;
          width: 100%;
        `
      : pickAndApplyBackgroundColor(component, inFocusedState);
    return css`
      ${background};
      ${parsePosition(component.position)}
      ${flexDirection(component)}
      ${applyStyles(component, inFocusedState)}
      height: ${height} !important;
      width: ${padding}px !important;
      padding: unset !important;
      ${applyBorders(prevSlide)}
      > * {
        ${applyChildSizing(minSDKVersion, sizeKey)}
        &:not(:first-child) {
          ${spaceKey}: ${component.spacing || 0}px;
        }
      }
    `;
  }}
`;

function applyBorders(prevSlide: boolean) {
  if (prevSlide) {
    return css`
      border-left: unset !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `;
  }
  return css`
    border-right: unset !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `;
}

export default function TeasedSlide({
  component,
  prevSlide,
  padding,
  scale,
  inFocusedState,
  minSDKVersion,
}: TeasedSlideProps) {
  return (
    <SlideWrapper
      component={component}
      inFocusedState={inFocusedState}
      minSDKVersion={minSDKVersion}
      prevSlide={prevSlide}
      scale={scale}
      padding={padding}
    ></SlideWrapper>
  );
}
