import { AxiosError, AxiosInstance } from 'axios';

import account from './account.api';
import analytics from './analytics.api';
import apps from './apps.api';
import auth from './auth.api';
import campaigns from './campaings.api';
import { bareClient, client, preAuthClient } from './clients';
import developer from './developer.api';
import entitlements from './entitlements.api';
import files from './files.api';
import fonts from './fonts.api';
import integrations from './integrations.api';
import languages from './languages.api';
import legalCitations from './legalCitations.api';
import orgMembers from './orgMembers.api';
import orgs from './orgs.api';
import paywalls from './paywalls.api';
import platforms from './platforms.api';
import policies from './policies.api';
import products from './products.api';
import streams from './streams.api';
import { TOKEN_ERRORS } from './utils';

const api = {
  client,
  preAuthClient,
  bareClient,
  ...apps,
  ...auth,
  ...account,
  ...orgs,
  ...analytics,
  ...campaigns,
  ...paywalls,
  ...fonts,
  ...products,
  ...policies,
  ...orgMembers,
  ...integrations,
  ...platforms,
  ...streams,
  ...languages,
  ...legalCitations,
  ...entitlements,
  ...files,
  ...developer,
  TOKEN_ERRORS,
  setResponseErrorInterceptor(
    clients: AxiosInstance[],
    errorHandler: (error: AxiosError) => void
  ) {
    clients.forEach((client) =>
      client.interceptors.response.use(undefined, errorHandler)
    );
  },
};

export default api;
