import React from 'react';

import { Drawer, Layout, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useHistory } from 'react-router-dom';
import Responsive from 'src/components/Responsive/Responsive';
import { namiAliceGray, namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

const StyledSider = styled(Layout.Sider)`
  border-right: 0.2px solid rgba(76, 84, 90, 0.1);
  background-color: ${namiAliceGray};
  .ant-layout-sider-trigger {
    background: ${namiAliceGray} !important;
    border-right: 0.2px solid rgba(76, 84, 90, 0.1);
  }

  .ant-menu-inline-collapsed {
    li.ant-menu-item > svg {
      transform: translateY(5px) !important;
    }

    div.ant-menu-submenu-title > svg {
      transform: translateY(5px) !important;
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background-color: ${namiAliceGray};
  }
  .ant-drawer-body {
    padding: 10px 0px 0px !important;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item:hover {
    svg {
      path {
        fill: ${namiPrimaryBlue};
      }
      circle {
        fill: ${namiPrimaryBlue};
      }
      rect {
        fill: ${namiPrimaryBlue};
      }
      text {
        fill: ${namiPrimaryBlue};
      }
      ellipse {
        fill: ${namiPrimaryBlue};
      }
    }
  }
  .ant-menu-submenu-title:hover {
    svg {
      path {
        fill: ${namiPrimaryBlue};
      }
      circle {
        fill: ${namiPrimaryBlue};
      }
      rect {
        fill: ${namiPrimaryBlue};
      }
      text {
        fill: ${namiPrimaryBlue};
      }
      ellipse {
        fill: ${namiPrimaryBlue};
      }
    }
  }

  .ant-menu-item-selected > svg {
    path {
      fill: ${namiPrimaryBlue};
    }
    circle {
      fill: ${namiPrimaryBlue};
    }
    rect {
      fill: ${namiPrimaryBlue};
    }
    text {
      fill: ${namiPrimaryBlue};
    }
    ellipse {
      fill: ${namiPrimaryBlue};
    }
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title > svg {
    path {
      fill: ${namiPrimaryBlue};
    }
    circle {
      fill: ${namiPrimaryBlue};
    }
    rect {
      fill: ${namiPrimaryBlue};
    }
    text {
      fill: ${namiPrimaryBlue};
    }
    ellipse {
      fill: ${namiPrimaryBlue};
    }
  }

  .ant-menu-submenu-selected {
    background-color: #e6f6ff;
  }

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    height: 1px !important;
  }
`;

type MainNavMenuProps = {
  items: ItemType[];
  isCollapsedDesktop: boolean;
  isOpenMobile: boolean;
  setDesktopMenuCollapsed: (value: boolean) => void;
  setMobileMenuOpen: (value: boolean) => void;
  selectedMenuItemKey: string;
};

export default function MainNavMenu({
  items,
  isCollapsedDesktop,
  isOpenMobile,
  setDesktopMenuCollapsed,
  setMobileMenuOpen,
  selectedMenuItemKey,
}: MainNavMenuProps) {
  const history = useHistory();

  return (
    <>
      <Responsive size="mdUp">
        <StyledSider
          collapsible
          collapsed={isCollapsedDesktop}
          theme="light"
          onCollapse={setDesktopMenuCollapsed}
          className="intercom-desktopSidebarMenu"
        >
          <StyledMenu
            theme="light"
            mode="inline"
            style={{ backgroundColor: namiAliceGray }}
            selectedKeys={[selectedMenuItemKey]}
            items={items}
            onClick={(info) => {
              history.push(info.key);
            }}
          />
        </StyledSider>
      </Responsive>
      <Responsive size="mdDown">
        <StyledDrawer
          open={isOpenMobile}
          placement="left"
          maskClosable={true}
          closable={false}
          onClose={(_e) => setMobileMenuOpen(false)}
          width={260}
        >
          <StyledMenu
            theme="light"
            mode="inline"
            style={{ backgroundColor: namiAliceGray }}
            selectedKeys={[selectedMenuItemKey]}
            items={items}
            onClick={(info) => {
              history.push(info.key);
              setMobileMenuOpen(false);
            }}
          />
        </StyledDrawer>
      </Responsive>
    </>
  );
}
