import React, { useContext, useEffect, useState } from 'react';

import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import Page from 'src/components/Page/Page';
import Responsive from 'src/components/Responsive/Responsive';
import APIAccessWebPaywall from 'src/components/WebPaywalls/APIAccessWebPaywall';
import CustomFontWebPaywall from 'src/components/WebPaywalls/CustomFontWebPaywall';
import { useAppContext, useQueryParams } from 'src/hooks';

import { NavigationContext } from '../AdminRouter';
import ProfilePage from './account/ProfilePage';
import SecurityPage from './account/SecurityPage';
import OrgsPage from './all-orgs/OrgsPage';
import ApiAccessPage from './api-access/ApiAccessPage';
import AppsPage from './apps/AppsPage';
import BillingPage from './billing/BillingPage';
import FontSection from './brand/FontSection';
import OrgIntegrationsPage from './org-integrations/OrgIntegrationsPage';
import OrgForm from './org/OrgForm';
import TeamPolicyForm from './policies/TeamPolicyForm';
import SettingsDrawer from './SettingsDrawer';
import SettingsHeader from './SettingsHeader';
import StoreCredentialsTab from './storeCredentials/StoreCredentialsTab';
import TeamPage from './team/TeamPage';

export type SettingsTabs =
  | 'account'
  | 'security'
  | 'org'
  | 'apps'
  | 'billing'
  | 'storeCredentials'
  | 'org-integrations'
  | 'api-access'
  | 'team'
  | 'policies'
  | 'brand'
  | 'all-orgs';

export type SettingsActions = SettingsTabs | 'logout';

export const TAB_ENTITLEMENTS_MAP: Record<SettingsActions, string[]> = {
  account: [],
  security: [],
  org: ['org.get'],
  apps: ['app.list'],
  billing: ['org.billing.plan.view'],
  storeCredentials: ['org.secret.list'],
  'org-integrations': ['org.cdp_integration.list'],
  'api-access': ['account.token.create'],
  team: ['org.members.list'],
  policies: ['org.policy.list'],
  brand: ['font.list'],
  'all-orgs': [],
  logout: [],
};

const CONTENTS: Record<SettingsTabs, JSX.Element> = {
  account: <ProfilePage />,
  security: <SecurityPage />,
  org: <OrgForm />,
  apps: <AppsPage />,
  billing: <BillingPage />,
  storeCredentials: <StoreCredentialsTab />,
  'org-integrations': <OrgIntegrationsPage />,
  'api-access': <ApiAccessPage />,
  team: <TeamPage />,
  policies: <TeamPolicyForm />,
  brand: <FontSection />,
  'all-orgs': <OrgsPage />,
};

export default function SettingsPage() {
  const context = useAppContext();
  const navigationContext = useContext(NavigationContext);
  const history = useHistory();
  const currentTab = useQueryParams().get('tab');
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    currentTab || 'account'
  );
  const [isMobileDrawerOpen, toggleMobileDrawerOpen] = useState(false);
  const [isUpgradeOpen, setUpgradeOpen] = useState(false);
  const [isFontUpgradeOpen, setFontUpgradeOpen] = useState(false);

  useEffect(() => {
    navigationContext.hideNavigation();
    return () => navigationContext.showNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navigationContext.hideHeader();
    return () => navigationContext.showHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentTab) return;
    if (!(currentTab in TAB_ENTITLEMENTS_MAP)) {
      history.push(`/settings/?tab=account`);
      return;
    }

    const hasAccess = (
      TAB_ENTITLEMENTS_MAP[currentTab as SettingsTabs] || []
    ).every(context.userHasEntitlement);
    if (hasAccess) {
      setSelectedMenuItem(currentTab);
    } else {
      history.push(`/settings/?tab=account`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, context.userHasEntitlement]);

  return (
    <>
      <Responsive size="mdUp">
        <SettingsHeader
          size="mdUp"
          isMobileDrawerOpen={isMobileDrawerOpen}
          toggleMobileDrawerOpen={toggleMobileDrawerOpen}
        />
        <Page
          title="Settings"
          contentContainerClass="settings-content-container"
        >
          <SettingsDrawer
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={onChangeMenuItem}
            size="mdUp"
            mobileDrawerOpen={isMobileDrawerOpen}
          />
          <Card>{CONTENTS[selectedMenuItem as SettingsTabs] || null}</Card>
        </Page>
      </Responsive>
      <Responsive size="mdDown">
        <SettingsHeader
          size="mdDown"
          isMobileDrawerOpen={isMobileDrawerOpen}
          toggleMobileDrawerOpen={toggleMobileDrawerOpen}
        />
        <Page title="Settings">
          <SettingsDrawer
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={onChangeMenuItem}
            size="mdDown"
            mobileDrawerOpen={isMobileDrawerOpen}
          />
          <Card style={{ marginTop: 12 }}>
            {CONTENTS[selectedMenuItem as SettingsTabs] || null}
          </Card>
        </Page>
      </Responsive>
      {context.selectedOrg != null && (
        <>
          <APIAccessWebPaywall
            visible={isUpgradeOpen}
            onCancel={() => setUpgradeOpen(false)}
          />
          <CustomFontWebPaywall
            visible={isFontUpgradeOpen}
            onCancel={() => setFontUpgradeOpen(false)}
          />
        </>
      )}
    </>
  );

  function onChangeMenuItem(key: SettingsActions) {
    if (
      key === 'api-access' &&
      !context.planHasEntitlement('account.token.create')
    ) {
      return setUpgradeOpen(true);
    } else if (
      key === 'policies' &&
      !context.planHasEntitlement('org.policy.list')
    ) {
      return setUpgradeOpen(true);
    } else if (key === 'brand' && !context.planHasEntitlement('font.list')) {
      return setFontUpgradeOpen(true);
    } else if (key === 'logout') {
      return context.performLogout();
    }
    history.push(`/settings?tab=${key}`);
    setSelectedMenuItem(key);
    toggleMobileDrawerOpen(false);
  }
}
