import React from 'react';

import { Col, Layout, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import AppIcon from 'src/components/AppIcon/AppIcon';
import { namiPureWhite } from 'src/variables';

import NamiIcon from '../../NamiIcon/NamiIcon';

type MobileHeaderProps = {
  mobileMenuOpen: () => void;
  switchAppMenuOpen: () => void;
};

export default function MobileHeader({
  mobileMenuOpen,
  switchAppMenuOpen,
}: MobileHeaderProps) {
  const history = useHistory();
  return (
    <Layout.Header
      style={{
        backgroundColor: namiPureWhite,
        padding: '0px 10px',
        borderBottom: '0.2px solid rgba(76, 84, 90, 0.10)',
      }}
    >
      <Row justify="center" align="middle">
        <Col
          flex="56px"
          onClick={mobileMenuOpen}
          className="intercom-mobileSidebarOpen"
        >
          <NamiIcon color="primary" icon="bars" />
        </Col>
        <Col
          flex="auto"
          style={{ textAlign: 'center', verticalAlign: 'middle' }}
        >
          <div
            style={{ transform: 'translateY(7px)', cursor: 'pointer' }}
            onClick={() => history.push('/overview')}
          >
            <NamiIcon icon="ml logo" color="primary" size="large" />
          </div>
        </Col>
        <Col flex="56px">
          <AppIcon onClick={switchAppMenuOpen} />
        </Col>
      </Row>
    </Layout.Header>
  );
}
