import React from 'react';

import {
  eventTypeGroups,
  prettyEventTypeNames,
  TEventLogEventType,
} from 'src/api/types/developer.types';

import StatusSelect from '../../campaigns/StatusSelect';

type EventTypeFilterProps = {
  eventTypes: TEventLogEventType[] | null;
  onChange: (value: string[] | null) => void;
};

export default function EventTypeFilter({
  eventTypes,
  onChange,
}: EventTypeFilterProps) {
  const allAvailableTypesCount = Object.keys(prettyEventTypeNames).length;
  const options = Object.entries(eventTypeGroups).reduce(
    (output, [group, types]) => {
      return [
        ...output,
        {
          label: group,
          options: types.reduce((output, type) => {
            return [
              ...output,
              {
                label: prettyEventTypeNames[type as TEventLogEventType],
                key: type,
                value: type,
              },
            ];
          }, [] as { label: string; key: TEventLogEventType; value: TEventLogEventType }[]),
        },
      ];
    },
    [] as {
      label: string;
      options: {
        label: string;
        key: TEventLogEventType;
        value: TEventLogEventType;
      }[];
    }[]
  );

  return (
    <StatusSelect
      as={StatusSelect}
      mode="multiple"
      maxTagCount={(eventTypes || []).length === allAvailableTypesCount ? 0 : 2}
      optionFilterProp="text"
      options={options}
      value={eventTypes || []}
      onChange={(value: string[]) => onChange(value)}
      label={eventTypes && eventTypes.length ? 'Types:' : ''}
      placeholder={
        <>
          Filter by <b>Event Type</b>
        </>
      }
      maxTagPlaceholder={(omittedValues: any[]) => {
        if (omittedValues.length === allAvailableTypesCount) return 'All';
        return `+${omittedValues.length} more`;
      }}
      dropdownMatchSelectWidth={false}
    />
  );
}
