import React, { useMemo } from 'react';

import { Logout, SettingsOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { Avatar, Dropdown, MenuProps, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'src/hooks';
import QueryKeys from 'src/hooks/queries/queryKeys';
import { useActions } from 'src/hooks/redux.hooks';
import PaginationSlice from 'src/redux/PaginationSlice';
import { defaultImage } from 'src/services/helpers';

export default function AvatarMenu() {
  const history = useHistory();
  const context = useAppContext();
  const queryClient = useQueryClient();
  const { setPaywallsPage } = useActions(PaginationSlice.actions);
  const user = context.user;
  const image =
    user?.avatar ||
    defaultImage(
      user?.first_name && user?.last_name
        ? `${user.first_name}+${user.last_name}`
        : user?.first_name || ''
    );
  const org = context.selectedOrg;

  const orgMenuItems: MenuProps['items'] = useMemo(() => {
    return (context.user?.permissions || []).map((org) => {
      return {
        label: (
          <Space direction="horizontal" size={10}>
            <Avatar size="small" src={defaultImage(org.name)} />
            {org.name}
          </Space>
        ),
        key: org.id,
      };
    });
  }, [context.user]);

  const menuItems: MenuProps['items'] = [
    {
      label: 'Settings',
      key: 'settings',
      icon: <SettingsOutlined style={{ fontSize: 14 }} />,
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <Logout style={{ fontSize: 14 }} />,
    },
    {
      label: 'Switch organization',
      type: 'group',
      children: orgMenuItems,
    },
  ];

  const dropdownOnClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'settings') history.push('/settings/');
    else if (key === 'logout') context.performLogout();
    else {
      context.updateSelectedOrg(key);
      history.push('/overview/');
      setPaywallsPage(1);
      queryClient.invalidateQueries([QueryKeys.apps]);
    }
  };

  return (
    <>
      {context.user && (
        <Dropdown
          menu={{
            items: menuItems,
            onClick: dropdownOnClick,
            style: { paddingBottom: 12 },
            selectedKeys: [context.selectedOrg?.id || ''],
          }}
          placement="bottomRight"
          trigger={['click']}
          className="intercom-switchOrgDropdown"
        >
          <Space
            direction="horizontal"
            size={0}
            style={{ cursor: 'pointer', float: 'right' }}
            className="intercom-currentOrgAvatar"
          >
            <Avatar size={35} src={image} />
            {orgAvatar()}
          </Space>
        </Dropdown>
      )}
    </>
  );

  function orgAvatar() {
    if (org)
      return (
        <Avatar
          size={25}
          src={defaultImage(org.name)}
          style={{
            marginTop: 16,
            cursor: 'pointer',
            marginLeft: '-10px',
            border: '1px solid white',
          }}
        />
      );
    return <></>;
  }
}
