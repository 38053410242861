import { useMemo } from 'react';

import { TFilterIdentifier } from '../api/types/campaign.types';
import countries from '../countries';
import languages from '../languages';
import {
  formFactorLabelMap,
  osNameLabelMap,
} from '../pages/admin/campaigns/detail/selectors/mapsAndOptions';
import { useCDPAudiencesQuery } from './queries/campaign.hooks';
import { usePlatformsQuery } from './queries/platform.hooks';

export function useRuleIdentifierJoinerMap(): Record<
  TFilterIdentifier,
  (values: string[]) => string
> {
  const platforms = usePlatformsQuery();
  const cdpAudiences = useCDPAudiencesQuery();

  return useMemo(() => {
    const platformMap = platforms.data?.reduce((output, { id, name }) => {
      return { ...output, [id]: name };
    }, {} as Record<string, string>);

    const cdpAudienceMap = (cdpAudiences.data?.results || []).reduce(
      (output, audience) => {
        return {
          ...output,
          [audience.id]: audience.display_name,
        };
      },
      {} as Record<string, string>
    );

    const simpleJoiner = buildValuesJoiner();
    return {
      'Platform.id': buildValuesJoiner(platformMap),
      'Device.os_name': buildValuesJoiner(osNameLabelMap),
      'Device.form_factor': buildValuesJoiner(formFactorLabelMap),
      'Request.form_factor': buildValuesJoiner(formFactorLabelMap),
      'Device.app_version': simpleJoiner,
      'Device.os_country': buildValuesJoiner(countries),
      'Device.marketplace_country': buildValuesJoiner(countries),
      'Device.os_language': buildValuesJoiner(languages),
      'Device.os_version': simpleJoiner,
      'Device.sdk_version': simpleJoiner,
      'Device.id': simpleJoiner,
      'Device.external_id': simpleJoiner,
      'Device.customer_data_platform_id': simpleJoiner,
      'Device.cdp_audience': buildValuesJoiner(cdpAudienceMap),
      'Device.geo_ip_country': buildValuesJoiner(countries),
    };
  }, [platforms.data, cdpAudiences.data]);
}

function buildValuesJoiner(map?: Record<string, string>) {
  return (values: string[]): string => {
    const valueArray = map
      ? values.map((value) => map[value] || map[value.toUpperCase()])
      : values;
    if (valueArray.length > 1) {
      const last = valueArray.pop();
      return valueArray.join(', ') + ', or ' + last;
    }
    return valueArray.join(', ');
  };
}
