import { useQuery } from '@tanstack/react-query';
import { TApiOptions } from 'src/api/types/main.types';

import api from '../../api';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function useEventLogQuery(apiOptions: TApiOptions, live: boolean) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery(
    [QueryKeys.eventsLog, appId, ...spreadApiOptions(apiOptions)],
    () => api.getEventLog(appId, apiOptions),
    {
      refetchInterval: live ? 10000 : false,
    }
  );
}
