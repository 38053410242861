import React from 'react';

import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

type LoadingProps = {
  style?: React.CSSProperties;
};

const Wrapper = styled.div`
  min-height: 100vh;
`;

export default function Loading({ style }: LoadingProps) {
  return (
    <Wrapper style={style}>
      <Loader active={true} />
    </Wrapper>
  );
}
