import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { TRadioButton } from '../../../api/types/paywallTemplate.types';
import {
  backgroundColor,
  dropShadow,
  transition,
  widthAndHeight,
} from '../css';
import HoverTag from './HoverTag';

type ComponentProps<T> = {
  component: T;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const RadioButtonWrapper = styled.div<{
  component: TRadioButton;
}>`
  display: flex;
  ${({ component }) => {
    return css`
      ${applyStyles(component)}
    `;
  }}
`;
const RadioButtonInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
`;

function applyStyles(component: TRadioButton): FlattenSimpleInterpolation {
  const isActive = component.active || false;
  const fillColor = isActive
    ? component.activeFillColor || 'transparent'
    : component.inactiveFillColor || 'transparent';
  const borderColor = isActive
    ? component.activeBorderColor || 'transparent'
    : component.inactiveBorderColor || 'transparent';
  return css`
    ${transition()}
    ${widthAndHeight(component)};
    border-color: ${borderColor} !important;
    border-width: ${isActive
      ? component.activeBorderWidth || 0
      : component.inactiveBorderWidth || 0}px !important;
    ${isActive && dropShadow(component)}
    border-style: solid;
    border-radius: 100%;
    padding: ${component.innerPadding || 0}px !important;
    > * {
      ${backgroundColor(fillColor)};
    }
  `;
}

export default function RadioButton({
  component,
}: ComponentProps<TRadioButton>) {
  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <RadioButtonWrapper component={component}>
        <RadioButtonInner />
      </RadioButtonWrapper>
    </HoverTag>
  );
}
